import React from 'react'

import { Dialog } from 'primereact/dialog'
import DialogFooter from '../dialogFooter'
import TiposDeRecebimentoTable from '../../views/tiposDeRecebimento/tiposDeRecebimentoTable'
import GeneralServices from '../../app/service/generalServices'
import TiposDeRecebimentoService from '../../app/service/tiposDeRecebimentoService'
import HandleErrorService from '../../app/service/handleErrorService'
import ConstantsUtil from '../../context/constantsUtil'

class ChooseTipoDeRecebimentoDialog extends React.Component {

    constructor(){
        super()
        this.tiposDeRecebimentoService = new TiposDeRecebimentoService()
    }

    state = {

        selectedTipoDeRecebimento: '',
        tipoDeRecebimentoList: [],
        filteredTipoDeRecebimentoList: [],

        searchText: '',

        didUpdated: false,

        loading: false

    }

    componentDidUpdate(){
        this.setStates()
    }

    setStates = () => {
        if( (this.props.visible && !this.state.didUpdated) || this.props.forceUpdate ){
            this.setState({searchText: ''})

            var list = []
            if(!this.props.list || this.props.list.length === 0){
                this.setState({loading: true})
                // this.tiposDeRecebimentoService.searchByContaBancaria(this.props.contaBancariaId)
                this.tiposDeRecebimentoService.search(true)
                .then(response => {
                    list = response.data
                    if(this.props.fromOFX){
                        //https://trello.com/c/IIKEgIRZ/664-corrigir-tratamento-de-erro-ao-tentar-realizar-lan%C3%A7amento-banc%C3%A1rio-de-dinheiro
                        list = list.filter(tipoDeRecebimento => tipoDeRecebimento.natureza !== ConstantsUtil.naturezaTipoDeRecebimentoDinheiroLabel)
                    }
                    this.setState({tipoDeRecebimentoList: list})
                    this.setState({filteredTipoDeRecebimentoList: list})
                })
                .catch(error => {
                    HandleErrorService.handleError(error)
                })
                .finally( () => {
                    this.setState({loading: false})
                } )
            }
            else{
                list = this.props.list
                this.setState({tipoDeRecebimentoList: list})
                this.setState({filteredTipoDeRecebimentoList: list})
            }

            
            this.setState({didUpdated: true})

        }
    }

    handleChange = async (event) => {
        const value = event.target.value
        const name = event.target.name
        await this.setState({ [name]: value })
        this.filter()
    }

    handleKeyPress = async (e) => {
        if (e.key === "Enter") {
            const filteredList = JSON.parse(JSON.stringify(this.state.filteredTipoDeRecebimentoList))
            if(filteredList.length === 1){
                await this.setState({selectedTipoDeRecebimento: filteredList[0]})
                this.callSave()
            }
          }
    }

    filter  = () => {
        
        this.setState({
            filteredTipoDeRecebimentoList:
                JSON.parse(
                    JSON.stringify(
                        this.state.tipoDeRecebimentoList.filter(tipoDeRecebimento => {
                            return (
                                GeneralServices.includeString(tipoDeRecebimento.nomeTipoDeRecebimento, this.state.searchText)
                                // tipoDeRecebimento.nomeTipoDeRecebimento.toLowerCase().includes(this.state.searchText.toLowerCase())
                                ||
                                GeneralServices.includeString(tipoDeRecebimento.numeroDeChamada, this.state.searchText)
                                // ((tipoDeRecebimento.id*5).toString().includes(this.state.searchText))
                            ) 
                        }
                        )
                    )
                )
        })
    }

    handleSelectionTipoDeRecebimentoChange = (value) => {
        if(value === null){ //significa que clicou em cima do Tipo de Recebimento que estava selecionado antes
            this.callSave()
        } else{
            this.setState({selectedTipoDeRecebimento: value})
        }
    }

    hideDialog = () => {
        this.setState({didUpdated: false})
        this.props.hideDialog()
    }

    callSave = async () => {
        await this.props.handleSelectionTipoDeRecebimentoChange(this.state.selectedTipoDeRecebimento)
        this.hideDialog()
    }

    render () {

        const chooseTipoDeRecebimentoDialogFooter = (
            <DialogFooter save = {this.callSave} hideDialog = {this.hideDialog} disabled={this.props.disabled}/>
        )

        return (
            <>
            <Dialog 
                    visible={this.props.visible}
                    style={{ width: '70vw' }}
                    header={this.props.header}
                    modal
                    className="p-fluid"
                    footer={chooseTipoDeRecebimentoDialogFooter}
                    onHide={this.hideDialog}
            >

                <div className="p-field">
                    <label htmlFor="search conta">Busca</label>
                    <input style={{color: '#000'}}
                        autoFocus   
                        className={"form-control " }
                        type="text"
                        id="searchText"
                        name="searchText"
                        value={this.state.searchText}
                        placeholder="Pesquise pelo nome ou pelo número de chamada"
                        onChange={this.handleChange}
                        onKeyPress={this.handleKeyPress}
                    />
                </div>

                <br />

                <TiposDeRecebimentoTable 
                        list = { this.state.filteredTipoDeRecebimentoList }
                        search={this.searchTiposDeRecebimento}
                        nomeTipoDeRecebimentoSearch={this.state.searchText.toUpperCase()}
                        loading={this.state.loading}
                        // push={this.props.history.push}
                        // loading = {this.state.loadingTiposDeRecebimentoTable}
                        simpleView={true}
                        handleSelectionTipoDeRecebimentoChange={this.handleSelectionTipoDeRecebimentoChange}
                    />


            </Dialog>
            </>
        )
    }

}

export default ChooseTipoDeRecebimentoDialog