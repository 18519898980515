import React from 'react'

import { Menu } from 'primereact/menu'
import { Button } from 'primereact/button'
import { Badge } from 'primereact/badge';
import MenuItem from './menuItem';
import ConstantsUtil from '../../context/constantsUtil';
import NotificacaoService from '../../app/service/central/notificacaoService';
import HandleErrorService from '../../app/service/handleErrorService';
import NotificacaoDialog from './notificacao/notificacaoDialog';
import AuthService from '../../app/service/authService';

class UserMenu extends React.Component {

    constructor() {
        super();
        this.menu = React.createRef()
        this.notificacaoService = new NotificacaoService()
        // this.toast = React.createRef()
    }

    state = {

        qtdNotificacoes: 0,

        displayNotificacaoDialog: false,

    }

    componentDidMount(){
        this.countNotificacoes()
    }

    countNotificacoes = () => {

        if(!this.props.context.isAuth){
            return;
        }

        this.notificacaoService.count()
        .then(response => {
            this.setState({qtdNotificacoes: response.data})
        })
        .catch(error => {
            HandleErrorService.handleError(error)
        })
    }

    render() {

        const renderNotificacao = () => {
            if(this.state.qtdNotificacoes){
                return (
                    <Badge
                        style={{marginTop: '1px', marginLeft: '40px', fontSize: '0.6rem', maxHeight: '40px'}}
                        severity="danger"
                        value={this.state.qtdNotificacoes}
                    />
                )
            }
        }

        const renderNotificacaoDialog = () => {
            return(
                <>
                    <NotificacaoDialog
                        visible={this.state.displayNotificacaoDialog}
                        hideDialog={ () => this.setState({displayNotificacaoDialog: false}) }
                    />
                </>
            )
        }

        const menuItem = (item) => { 
            return (
                <MenuItem item={item}/>
            )
        }

        const notificacoes = {
            label:
                <div>
                    Notificações
                    {renderNotificacao()}
                </div>,
            icon: 'pi pi-bell',
            command: () => {this.setState({displayNotificacaoDialog: true})}
        }

        const alterarSenha = {
            label: 'Alterar Senha',
            icon: 'pi pi-refresh',
            url: "/changePassword",
        }

        const cadastrarUsuario = {
            label: 'Cadastro de Usuários',
            icon: 'pi pi-user',
            url: "/usuarios",
            disabled: !this.props.context.perfil.isAdmin,
            template: menuItem,
        }

        const empresas = {
            label: 'Empresas',
            icon: 'pi pi-list',
            url: "/empresas",
            template: menuItem,
            disabled: !this.props.context.perfil.isAdmin,
        }

        const grupoDeEmpresas = {
            label: 'Grupos de Empresas',
            icon: 'pi pi-sitemap',
            url: "/gruposDeEmpresas",
            disabled: !this.props.context.perfil.isAdmin,
            template: menuItem,
        }

        const sair =  {
            label: 'Sair',
            icon: 'pi pi-sign-out',
            url: ConstantsUtil.logoutPath,
        }

        const items = [
            {
                // label: 'Opções ' + AuthService.getUserLoggedIn().perfil,
                label: 'Opções',
                items: [
                    notificacoes,
                    alterarSenha,
                    grupoDeEmpresas,
                    empresas,
                    cadastrarUsuario,
                    sair
                ]
            }
            
        ]

        if(this.props.context.isAuth){

            return (
                <div>
                    {/* <Toast ref={this.toast}></Toast> */}

                    <Menu model={items} popup ref={this.menu} id="popup_menu" />
                    <Button
                        className="p-button-raised p-button-secondary p-button-text"
                        icon={
                            <i className="pi pi-bars p-mr-4 p-text-secondary p-overlay-badge" style={{ fontSize: '1rem' }}>
                                {renderNotificacao()}
                            </i>
                        }
                        style={ {width: '70px'} }
                        onClick={(event) => this.menu.current.toggle(event)} 
                        aria-controls="popup_menu" 
                        aria-haspopup
                    />
                    {renderNotificacaoDialog()}
                </div>
            )
        }
        return false
    }

}

export default UserMenu