import React from 'react'
import Card from '../../../components/card'
import { withRouter } from 'react-router-dom'

import { AuthContext } from '../../../main/authProvider'
import GeneralServices from '../../../app/service/generalServices'
import HandleErrorService from '../../../app/service/handleErrorService'
import SelectMenu from '../../../components/selectMenu'
import TiposDeRecebimentoService from '../../../app/service/tiposDeRecebimentoService'
import { Button } from 'primereact/button'
import EditTipoDeRecebimentoDialog from '../../../components/tipoDeRecebimento/editTpoDeRecebimentoDialog'
import FichaDeRecebimentoTable from '../../../components/fichaDeRecebimento/fichaDeRecebimentoTable'
import FichaDeRecebimentoService from '../../../app/service/contasAReceber/fichaDeRecebimentoService'
import { Autocomplete, TextField } from '@mui/material'
import DateIntervalCalendar from '../../../components/calendar/dateIntervalCalendar'
import ConstantsUtil from '../../../context/constantsUtil'

class FichaDeRecebimento extends React.Component {

    constructor(){
        super();
        this.generalServices = new GeneralServices();
        this.tiposDeRecebimentoService = new TiposDeRecebimentoService();
        this.fichaDeRecebimentoService = new FichaDeRecebimentoService();

    }
    
    state = {
        tiposDeRecebimento: [],
        loading: false,
        tiposDeRecebimentoSelectList: [],
        selectedTipoDeRecebimentoValue: '',
        selectedTipoDeRecebimentoObj: {},
        inputTipoDeRecebimentoErrorClass: false,
        errorTipoDeRecebimentoMessage: '',

        viewTipoDeRecebimentoDialog: false,

        fichaDeRecebimento: [],

        selectedFilterType: '',
        selectedDateFilterType: '',
        initialDate: '',
        initialDateView: '',
        finalDate: '',
        finalDateView: '',
        isSearchingLancamentos: false,
        isFinishedSearch: false,

        habilitaBusca: true,

    }


    componentDidMount(){
        this.searchTiposDeRecebimento()
        this.setState({selectedFilterType: this.fichaDeRecebimentoService.getFilterTypeList()[2].value})
    }

    handleTipoDeRecebimentoChange = async (event) => {
        const value = event.target.value
        const name = event.target.name
        await this.setState({ [name]: value })
        const obj = this.state.tiposDeRecebimento.find(tipoDeRecebimento => 
            tipoDeRecebimento.nomeTipoDeRecebimento === this.state.selectedTipoDeRecebimentoValue)
        this.setState({ selectedTipoDeRecebimentoObj: obj })
    }

    handleFilterTypeChange = async (event) => {
        const value = event.target.value
        const name = event.target.name
        await this.setState({ [name]: value })
        // this.resetDateView()
    }

    resetDateView = () => {
        this.setState({initialDate: ''})
        this.setState({initialDateView: ''})
        this.setState({finalDate: ''})
        this.setState({finalDateView: ''})
    }

    handleNavigatorDateChange = (event, e) => {
        e.onChange(event.originalEvent, event.value)
    }

    handleDateFilterChange = (selectedDateFilterType) => {
        this.setState({selectedDateFilterType})
    }


    handleDateChange = async (initialDate, finalDate) => {
        await this.setState({initialDate})
        
        await this.setState({initialDateView: GeneralServices.convertBrStringToJsDate(initialDate)})

        await this.setState({finalDate})
        await this.setState({finalDateView: GeneralServices.convertBrStringToJsDate(finalDate)})

    }

    hideDialog = () => {
        this.setState({viewTipoDeRecebimentoDialog: false})
    }

    searchTiposDeRecebimento = () => {
        this.setState({loading: true})
        this.tiposDeRecebimentoService.search()
        .then(response => {
            this.setState({tiposDeRecebimento: response.data})
            this.setState({loading: false})
            var filteredTipoDeRecebimentoList = response.data.filter(tipoDeRecebimento => {
                return (
                    tipoDeRecebimento.natureza !== ConstantsUtil.naturezaTipoDeRecebimentoAutomaticoLabel
                    && tipoDeRecebimento.natureza !== ConstantsUtil.naturezaTipoDeRecebimentoAplicacaoFinanceiraLabel
                    && tipoDeRecebimento.natureza !== ConstantsUtil.naturezaTipoDeRecebimentoDinheiroLabel
                    && tipoDeRecebimento.natureza !== ConstantsUtil.naturezaTipoDeRecebimentoOutrosLabel
                    && tipoDeRecebimento.natureza !== ConstantsUtil.naturezaTipoDeRecebimentoEstornoTarifaLabel
                )
            })
            this.setState({tiposDeRecebimentoSelectList: this.fichaDeRecebimentoService.getTiposDeRecebimentoSelectList(filteredTipoDeRecebimentoList) })
        }).catch(error => {
            HandleErrorService.handleError(error)
            this.setState({loading: false})
        })
    }

    resetView = () => {
        this.setState({inputTipoDeRecebimentoErrorClass: false})
        this.setState({errorTipoDeRecebimentoMessage:''})
        this.setState({fichaDeRecebimento: []})
    }

    checkData = () => {
        var check = true

        if(!this.state.selectedTipoDeRecebimentoValue){
            this.setState({inputTipoDeRecebimentoErrorClass: true})
            this.setState({errorTipoDeRecebimentoMessage:'É necessário escolher o Tipo de Recebimento'})
            check = false
        }

        return check
    }

    toggleButton = () => {
        this.setState({habilitaBusca: !this.state.habilitaBusca})
    }

    callSearch = () => {

        this.resetView()

        if(this.checkData()){
            this.setState({loading: true})
            // this.toggleButton()
            this.setState({habilitaBusca: false})
            this.setState({isSearchingLancamentos: true})
            this.setState({isFinishedSearch: false})
            this.fichaDeRecebimentoService.getLancamentosByDateInterval(
                {
                    tipoDeRecebimento : this.state.selectedTipoDeRecebimentoObj,
                    dateFilterType : this.state.selectedFilterType,
                    initialLancamentoDate : this.state.initialDate,
                    finalLancamentoDate : this.state.finalDate
                }
            )
            .then(response => {
                this.setState({fichaDeRecebimento: response.data})
                // console.log("ficha: ", response.data)

                this.setState({isSearchingLancamentos: false})
                this.setState({isFinishedSearch: true})

            })
            .catch(error => {
                HandleErrorService.handleError(error)
                this.setState({isSearchingLancamentos: false})
                this.setState({isFinishedSearch: true})
            })
            .finally(() => {
                this.setState({loading: false})
            })
        }
    }

    handleMuiChange = async (event, value) => {
        const realValue = value ? value.value : ''
        await this.setState({ selectedTipoDeRecebimentoValue: realValue })
        const obj = this.state.tiposDeRecebimento.find(tipoDeRecebimento => 
            tipoDeRecebimento.nomeTipoDeRecebimento === this.state.selectedTipoDeRecebimentoValue)
        this.setState({ selectedTipoDeRecebimentoObj: obj })
    }

    handleMuiKeyUp = (event) => {
        if(event.code === "Enter" || event.code === "NumpadEnter"){
            this.callSearch()
        }
    }

    render() {

        const renderCalendarFilters = () => {
            if(this.state.selectedFilterType === this.fichaDeRecebimentoService.getDataLancamentoValue() ||
               this.state.selectedFilterType === this.fichaDeRecebimentoService.getDataCreditoValue())
            {
                return (
                    <>
                    <DateIntervalCalendar
                            htmlFor="SelectDatePeriod"
                            label="Escolher período"
                            handleFilterChange={this.handleDateFilterChange}
                            handleDateChange={this.handleDateChange}
                            // missingFilterType={this.state.missingFilterType}
                            // missingAnyDate={this.state.missingAnyDate}
                            disabled={!this.state.habilitaBusca}
                        />
                    </>
                )
            }
        }

        const renderFichaDeRecebimentoTable = () => {
            if( (this.state.isFinishedSearch && !this.state.habilitaBusca) || this.state.loading){
                return (
                    <>
                    <div className="bs-docs-section" >
                        <FichaDeRecebimentoTable
                            tipoDeRecebimento={this.state.selectedTipoDeRecebimentoValue}
                            tipoDeRecebimentoId={this.state.selectedTipoDeRecebimentoObj.id}
                            tipoDeRecebimentoList={this.state.tiposDeRecebimento}
                            fichaDeRecebimento = {this.state.fichaDeRecebimento}
                            search={this.callSearch}
                            loading={this.state.loading}
                        />
                    </div>
                    </>
                )
            }
        }

        const renderButtons = () => {
            if(this.state.habilitaBusca){
                return (
                    <Button 
                        label="Buscar Lançamentos"
                        icon="pi pi-search"
                        onClick = {this.callSearch}
                        // style={ {maxHeight: '35px' } }
                        disabled = {this.state.isSearchingLancamentos}
                    />
                )
            }
            return (
                <>
                    <Button 
                        label="Voltar"
                        icon="pi pi-undo"
                        className="p-button-danger"
                        onClick = {this.toggleButton}
                        // style={ {maxHeight: '35px' } }
                        // disabled = {this.state.isSearchingLancamentos}
                    />
                </>
            )
        }

        return(
            <div className="bs-docs-section" >
            <Card title = "Fichas de Recebimento Individuais">
                <div className = "col-md-12">
                <div className="row">
                    <div className = "col-md-4">
                    <label htmlFor="nomeSelectTipoDeRecebimento">Tipo De Recebimento *</label>
                    </div>
                </div>
                <div className="row">
                    <div className = "col-md-3">
                    <Autocomplete
                        disablePortal
                        id="combo-box-tipo-de-recebimento"
                        options={this.state.tiposDeRecebimentoSelectList}
                        getOptionLabel={option => option.value}
                        // sx={{ width: 300 }}
                        size="small"
                        onChange={this.handleMuiChange}
                        // onKeyUp={this.handleMuiKeyUp}
                        disabled={!this.state.habilitaBusca}
                        renderInput={(params) => 
                            <TextField
                                required
                                error={this.state.inputTipoDeRecebimentoErrorClass}
                                helperText={this.state.errorTipoDeRecebimentoMessage}
                                {...params}
                                label="Selecione..."
                            />
                        }
                    />
                    </div>
                    <div className = "col-md-2">
                    <Button 
                        tooltip="Dados do Tipo De Recebimento" 
                        tooltipOptions={{position: 'top'}}
                        icon="pi pi-eye"
                        className={ "p-button-rounded p-mr-2 " }
                        style={ {display: this.state.selectedTipoDeRecebimentoValue === '' ? 'none' : ''} }
                        onClick={() => this.setState({viewTipoDeRecebimentoDialog: true})}
                    />
                    </div>
                </div>

                <br />

                <div className="row">
                    <div className = "col-md-4">
                    <label htmlFor="filtro">Filtrar Por</label>
                    <SelectMenu 
                        className={"form-control " }
                        name="selectedFilterType"
                        list= {this.fichaDeRecebimentoService.getFilterTypeList()} 
                        value={this.state.selectedFilterType}
                        onChange={this.handleFilterTypeChange}
                        disabled={!this.state.habilitaBusca}
                        // disabled
                    />
                    </div>
                    
                    {renderCalendarFilters()}

                </div>  
                <br />

                {renderButtons()}
                
                </div> {/* col-md-12 */}


                <EditTipoDeRecebimentoDialog  
                    hideDialog={this.hideDialog}
                    visible={this.state.viewTipoDeRecebimentoDialog}
                    header="Tipo de Recebimento"
                    state={this.state.selectedTipoDeRecebimentoObj}
                    // search={this.props.search}
                    push={this.props.push}
                    disabled={true}
                />

                {renderFichaDeRecebimentoTable()}


            </Card>
            <div className="d-flex "/>
        </div>              
        )
    }

}

FichaDeRecebimento.contextType = AuthContext

export default withRouter(FichaDeRecebimento)