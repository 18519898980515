import React from 'react'

import { Dialog } from 'primereact/dialog'
import DialogFooter from '../dialogFooter'

import * as popUp from '../../components/toastr'
import CustomCalendar from '../calendar/customCalendar'
import HandleErrorService from '../../app/service/handleErrorService'
import LancarFechamentoBancarioService from '../../app/service/conciliacaoBancaria/lancarFechamentoBancarioService'
import GeneralServices from '../../app/service/generalServices'
import InputChooseTipoDeRecebimento from '../tipoDeRecebimento/inputChooseTipoDeRecebimento'
import { InputNumber } from 'primereact/inputnumber'
import { InputTextarea } from 'primereact/inputtextarea'
import ConstantsUtil from '../../context/constantsUtil'
import InputChooseContaContabil from '../planoDeContas/inputChooseContaContabil'
import InputChooseTipoDePagamento from '../contasAPagar/inputChooseTipoDePagamento'
import FormGroup from '../form-group'
import SelectMenu from '../selectMenu'
import InputChooseContaBancaria from './inputChooseContaBancaria'
import CheckBoxComponent from '../relatorios/checkBoxComponent'

class EditLancamentoBancarioDialog extends React.Component {
    
    constructor(){
        super();
        // this.contaBancariaService = new ContaBancariaService()
        this.generalServices = new GeneralServices();
        this.lancarFechamentoBancarioService = new LancarFechamentoBancarioService();
    }

    state = {

        visible: true,

        idLancamentoFechamentoBancario: null,

        inputDateErrorClass: "",
        errorDateMessage: "",
        dataLancamento: '',
        dataLancamentoView: '',

        tipoLancamento: '',
        tipoDeLancamentoCompleto: '',
        tipoDeLancamentoBancarioOriginal: '',
        inputTipoDeLancamentoErrorClass: '',
        errorTipoDeLancamentoMessage: "Informe o Tipo de Lançamento",

        selectedTipoDeRecebimentoOuPagamento: '',
        selectedTipoDeRecebimentoOuPagamentoLabel: '',
        inputTipoDeRecebimentoErrorClass: '',
        errorTipoDeRecebimentoMessage: 'Escolha o tipo de recebimento',


        idSelectedContaBancaria: '',

        selectedContaBancariaContraPartida: '',
        selectedContaBancariaContraPartidaLabel: '',
        inputContaBancariaContraPartidaLabelErrorClass:'',

        valor: null,
        inputValorErrorClass: '',
        errorValorMessage: '',

        selectedContaContabil: '',
        selectedContaContabilLabel: '',
        inputContaContabilErrorClass:'',
        errorContaContabilMessage:'',

        historico: '',
        inputHistoricoErrorClass: '',
        errorHistoricoMessage: '',

        complementoHistorico: '',

        ofxSequence: '',

        gravarEscolha: true,

        didUpdated: false,
        allowUpdate: true,
    }


    componentDidUpdate(){
        this.setStates()
    }

    setStates = () => {
        if( ((this.props.visible && !this.state.didUpdated) || this.props.forceUpdate) && this.state.allowUpdate) {

            // console.log("lanc to edit: ", this.props.state)

            this.setState({visible: true})

            if(this.props.idSelectedContaBancaria){
                this.setState({idSelectedContaBancaria: this.props.idSelectedContaBancaria})
            } else{
                this.setState({idSelectedContaBancaria: this.props.state.contaBancaria.id})
            }

            this.setState({idLancamentoFechamentoBancario: this.props.state.idLancamentoFechamentoBancario})
            
            this.setState({dataLancamento: this.props.state.data})
            this.setState({dataLancamentoView: GeneralServices.convertBrStringToJsDate(this.props.state.data)})
            // this.setState({dataLancamentoView: this.props.state.dataLancamentoView})
            
            var selectedTipoDeRecebimentoOuPagamentoObj

            var partialTipoLancamento = ''
            if(this.props.tipoLancamentoBancario){
                partialTipoLancamento = this.props.tipoLancamentoBancario
            } else{
                partialTipoLancamento = this.props.state.tipoLancamentoBancario
            }

            var tipoLancamento = partialTipoLancamento
            this.setState({tipoLancamento})
            this.setState({tipoDeLancamentoCompleto: tipoLancamento})

            if(this.props.state.tipoFichaBancaria === ConstantsUtil.tipoFichaBancariaTransferenciaLabel) {
            
                this.setState({selectedContaBancariaContraPartida: {id: this.props.state.idContaBancariaContrapartida}})
                this.setState({selectedContaBancariaContraPartidaLabel: this.props.state.nomeContaBancariaContrapartida})
                
                if(partialTipoLancamento === ConstantsUtil.lancamentoBancarioRecebimentoLabel){
                    tipoLancamento = ConstantsUtil.lancamentoBancarioTransferenciaLabel
                    this.setState({tipoLancamento})
                    this.setState({tipoDeLancamentoCompleto: ConstantsUtil.lancamentoBancarioTransferenciaEntradaLabel})
                }

                else if(partialTipoLancamento === ConstantsUtil.lancamentoBancarioPagamentoLabel){
                    tipoLancamento = ConstantsUtil.lancamentoBancarioTransferenciaLabel
                    this.setState({tipoLancamento})
                    this.setState({tipoDeLancamentoCompleto: ConstantsUtil.lancamentoBancarioTransferenciaSaidaLabel})
                }
            }

            this.setState({tipoDeLancamentoBancarioOriginal: JSON.parse(JSON.stringify(tipoLancamento))})

            if(tipoLancamento === ConstantsUtil.lancamentoBancarioRecebimentoLabel){
                selectedTipoDeRecebimentoOuPagamentoObj = {id: this.props.state.idTipoDeRecebimento}
            }
            else {
                selectedTipoDeRecebimentoOuPagamentoObj = {id: this.props.state.idTipoDePagamento}
            }
            
            this.setState({selectedTipoDeRecebimentoOuPagamento: selectedTipoDeRecebimentoOuPagamentoObj})

            this.setState({selectedTipoDeRecebimentoOuPagamentoLabel: this.props.state.descricao})
            
            // console.log("debug: ", this.props.state)
            if(this.props.state.entrada && this.props.state.entrada !== 0){
                this.setState({valor: this.props.state.entrada})
            } else{
                this.setState({valor: this.props.state.saida})
            }

            this.setState({selectedContaContabilLabel: this.props.state.nomeContaContabil})

            this.setState({historico: this.props.state.historico})

            if(this.props.state.historico){
                this.setState({complementoHistorico: this.props.state.historico.split(ConstantsUtil.virgulaEspacoToRegexpSplit)[1]}) //Faz split apenas do 1° achado de ", " (vírgula seguido de espaço)
            }
            else{
                this.setState({complementoHistorico: ''})
            }

            this.setState({ofxSequence: this.props.state.ofxSequence})

            this.resetView()
                
            this.setState({didUpdated: true})
            if(this.props.doneForceUpdate){
                this.props.doneForceUpdate()
            }

            // this.setState({gravarEscolha: this.props.state.gravarEscolha})
            this.setState({gravarEscolha: true})
        }
    }

    handleValorChange = (event) => {
        let value = event.value
        if(value === 0){
            value = null
        }
        const name = event.target.name
        this.setState({ [name]: value })
    }

    handleKeyPress = async (e) => {
        await this.generalServices.sleep(1) //essa função é chamada no mesmo instante que a handleValorChange.
                                            //Precisa esperar um tempo de forma que o valor seja alterado.
        //it triggers by pressing the enter key
        if (e.key === "Enter") {
            this.callSave();
        }
    }
    
    handleChange = (event) => {
        const value = event.target.value
        const name = event.target.name
        this.setState({ [name]: value })
    }

    handleNavigatorDateChange = (event, e) => {
        e.onChange(event.originalEvent, event.value)
    }

    handleDateChange = async (event) => {
        const value = event.value
        var name = event.target.name
        await this.setState({ [name]: GeneralServices.convertJSDateToBrDate(value) })
        var nameView = name+"View"
        await this.setState({ [nameView]: value })
    }

    handleTipoDeLancamentoChange = async (event) => {
        const value = event.target.value
        const name = event.target.name
        await this.setState({ tipoLancamento: value.split("_")[0] })
        await this.setState({ tipoDeLancamentoCompleto: value })

        this.setState({selectedTipoDeRecebimentoOuPagamento: ''})
        this.setState({selectedTipoDeRecebimentoOuPagamentoLabel: ''})

        this.setState({selectedContaBancariaContraPartida: ''})
        this.setState({selectedContaBancariaContraPartidaLabel: ''})
    }

    handleSelectionTipoDeRecebimentoChange = (tipoDeRecebimento, label) => {
        this.setState({selectedTipoDeRecebimentoOuPagamento: tipoDeRecebimento})
        this.setState({selectedTipoDeRecebimentoOuPagamentoLabel: label})

        this.setState({selectedContaContabil: ''})
        this.setState({selectedContaContabilLabel: ''})
        
        this.setState({historico: ''})
    }

    handleSelectionTipoDePagamentoChange = (tipoDePagamento, label) => {
        this.setState({selectedTipoDeRecebimentoOuPagamento: tipoDePagamento})
        this.setState({selectedTipoDeRecebimentoOuPagamentoLabel: label})

        this.setState({selectedContaContabil: ''})
        this.setState({selectedContaContabilLabel: ''})
        
        this.setState({historico: ''})
    }

    handleSelectionContaContabilChange = (selectedContaContabil, selectedContaContabilLabel) => {
        this.setState({selectedContaContabil})
        this.setState({selectedContaContabilLabel})
    }

    handleSelectionContaBancariaContraPartidaChange = async (selectedContaBancariaContraPartida, selectedContaBancariaContraPartidaLabel) => {
        this.setState({selectedContaBancariaContraPartida})
        this.setState({selectedContaBancariaContraPartidaLabel})
    }

    hideDialog = () => {
        this.setState({didUpdated: false})
        this.setState({allowUpdate: true})
        this.props.hideDialog()
    }

    resetView = () => {
        this.setState({inputDateErrorClass: ''})
        this.setState({errorDateMessage: ''})

        this.setState({inputTipoDeLancamentoErrorClass: ''})

        this.setState({inputTipoDeRecebimentoErrorClass: ''})

        this.setState({inputTipoDePagamentoErrorClass: ''})

        this.setState({inputContaBancariaContraPartidaLabelErrorClass: ''})
        
        this.setState({inputValorErrorClass: ''})
        this.setState({errorValorMessage: ''})
        
        this.setState({inputContaContabilErrorClass: ''})
        this.setState({errorContaContabilMessage: ''})

        this.setState({inputHistoricoErrorClass: ''})
        this.setState({errorHistoricoMessage: ''})
    } 
    
    checkData = () => {
        var check = true

        if(!this.state.dataLancamento){
            this.setState({inputDateErrorClass: ConstantsUtil.primereactInputErrorClass})
            this.setState({errorDateMessage: "Informe a data"})
            check = false
        }

        if(!this.state.tipoDeLancamentoCompleto){
            this.setState({inputTipoDeLancamentoErrorClass: ConstantsUtil.bootstrapInputErrorClass})
            check = false
        }

        if(this.isTipoDeLancamentoTransferencia()){
            if(!this.state.selectedContaBancariaContraPartida){
                this.setState({inputContaBancariaContraPartidaLabelErrorClass: ConstantsUtil.bootstrapInputErrorClass})
                check = false
            }
        }

        if(this.state.tipoDeLancamentoCompleto === ConstantsUtil.lancamentoBancarioRecebimentoLabel){
            
            if(!this.state.selectedTipoDeRecebimentoOuPagamento){
                this.setState({inputTipoDeRecebimentoErrorClass: ConstantsUtil.bootstrapInputErrorClass})
                check = false
            }

        }

        if(this.state.tipoDeLancamentoCompleto === ConstantsUtil.lancamentoBancarioPagamentoLabel){
            if(!this.state.selectedTipoDeRecebimentoOuPagamento){
                this.setState({inputTipoDePagamentoErrorClass: ConstantsUtil.bootstrapInputErrorClass})
                check=false
            }

        }
        
        if(!this.state.valor || this.state.valor === 0){
            this.setState({inputValorErrorClass: ConstantsUtil.bootstrapInputErrorClass})
            this.setState({errorValorMessage: "Informe o valor do lançamento"})            
            check = false
        }

        if(!this.state.selectedContaContabilLabel && this.state.selectedTipoDeRecebimentoOuPagamentoLabel.includes(ConstantsUtil.avulsoTipoDeRecebimentoLabel)){
            this.setState({inputContaContabilErrorClass: ConstantsUtil.bootstrapInputErrorClass})
            this.setState({errorContaContabilMessage: ConstantsUtil.contaContabilErrorMessage })
            check = false
        }

        if(!this.state.historico && this.state.selectedTipoDeRecebimentoOuPagamentoLabel.includes(ConstantsUtil.avulsoTipoDeRecebimentoLabel)){
            this.setState({inputHistoricoErrorClass: ConstantsUtil.primereactInputErrorClass})
            this.setState({errorHistoricoMessage: ConstantsUtil.historicoErrorMessage })
            check = false
        }

        return check
    }

    callSave = () => {
        this.resetView()
        if(this.checkData()){
            this.save()
        }
    }

    save = () => {
        if(this.props.fromOFX){
            // console.log(this.montaObjAlterado(this.getContasBancariasOrigemEDestino()))
            this.props.editLancamentoFromOFX(this.montaObjAlterado(this.getContasBancariasOrigemEDestino()))
            this.hideDialog()
        }
        else{
            this.realSave()
        }
    }
    
    realSave = () => {

        this.props.beginLoadingFichaBancariaTable()
        this.setState({allowUpdate: false})
        this.setState({visible: false})
        this.lancarFechamentoBancarioService.update(this.montaObjAlterado(this.getContasBancariasOrigemEDestino()))
        .then(response => {
            popUp.successPopUp("Lançamento bancário alterado com sucesso!")
            if(this.props.search){
                this.props.search()
            }
            this.hideDialog()
            this.setState({didUpdated: false})
            this.props.endLoadingFichaBancariaTable()
        }).catch(error => {
            this.props.endLoadingFichaBancariaTable()
            HandleErrorService.handleError(error)
            // console.log("realSave error: ", error)
            this.setState({visible: true})
        })
        this.setState({didUpdated: false})
    }

    getContasBancariasOrigemEDestino = () => {
        //Já popula para o caso de não ser transferência
        var idContaOrigem = this.state.idSelectedContaBancaria;
        var idContaDestino= '';

        if(this.isTipoDeLancamentoTransferenciaEntrada()){
            idContaOrigem = this.state.selectedContaBancariaContraPartida.id
            // idContaDestino = this.props.idSelectedContaBancaria
            idContaDestino = this.state.idSelectedContaBancaria
        }
        else if(this.isTipoDeLancamentoTransferenciaSaida()){
            // idContaOrigem = this.props.idSelectedContaBancaria
            idContaOrigem = this.state.idSelectedContaBancaria
            idContaDestino = this.state.selectedContaBancariaContraPartida.id
        }

        return {idContaOrigem, idContaDestino}
    }

    montaObjAlterado = (constasBancariasOrigemEDestinoObj) => {
        // console.log("this.state.selectedTipoDeRecebimentoOuPagamento: ", this.state.selectedTipoDeRecebimentoOuPagamento)
        
        let idContaOrigem = constasBancariasOrigemEDestinoObj.idContaOrigem
        let idContaDestino = constasBancariasOrigemEDestinoObj.idContaDestino

        const obj = {
            idLancamento: this.state.idLancamentoFechamentoBancario,
            dataCredito: this.state.dataLancamento,
            tipoDeLancamentoBancario: this.state.tipoLancamento,
            tipoDeLancamentoCompleto: this.state.tipoDeLancamentoCompleto,
            tipoDeLancamentoBancarioOriginal: this.state.tipoDeLancamentoBancarioOriginal,

            idTipoDeRecebimento: this.state.tipoLancamento === ConstantsUtil.lancamentoBancarioRecebimentoLabel ? 
                this.state.selectedTipoDeRecebimentoOuPagamento.id : null,

            tipoDeRecebimento: this.state.tipoLancamento === ConstantsUtil.lancamentoBancarioRecebimentoLabel ? 
            this.state.selectedTipoDeRecebimentoOuPagamento : null, 

            idTipoDePagamento: this.state.tipoLancamento === ConstantsUtil.lancamentoBancarioPagamentoLabel ? 
                this.state.selectedTipoDeRecebimentoOuPagamento.id : null,

            tipoDePagamento: this.state.tipoLancamento === ConstantsUtil.lancamentoBancarioPagamentoLabel ? 
                this.state.selectedTipoDeRecebimentoOuPagamento : null,

            idContaBancaria: idContaOrigem,
            idContaBancariaDestino: idContaDestino, //será utilizado pelo backend apenas quando se tratar de transferência
            idContaBancariaContrapartida: this.state.selectedContaBancariaContraPartida.id,
            nomeContaBancariaContrapartida: this.state.selectedContaBancariaContraPartidaLabel,
            complementoHistorico: this.state.complementoHistorico,
            ofxSequence: this.state.ofxSequence,
            valor: this.state.valor,

            gravarEscolha: this.state.gravarEscolha,

            /*
                Os dois abaixo são utilizados pelo backend apenas quando se trata de um lancamento de fechamento bancário
                do Tipo de Recebimento AVULSO.
            */
            idContaContabil: parseInt(this.state.selectedContaContabil.numeroDeChamada)/5,
            historicoAvulso: this.state.historico
        }

        // console.log("montaObjAlterado final: ", obj)

        return obj
    }
    
    isTipoDeLancamentoTransferencia = () => {
        return this.state.tipoLancamento === ConstantsUtil.lancamentoBancarioTransferenciaLabel
    }

    isTipoDeLancamentoTransferenciaEntrada = () => {
        return this.isTipoDeLancamentoTransferencia()
        && (this.state.tipoDeLancamentoCompleto === ConstantsUtil.lancamentoBancarioTransferenciaEntradaLabel)
    }

    isTipoDeLancamentoTransferenciaSaida = () => {
        return this.isTipoDeLancamentoTransferencia()
        && (this.state.tipoDeLancamentoCompleto === ConstantsUtil.lancamentoBancarioTransferenciaSaidaLabel)
    }

    // onRenderTipoDeRecebimentoDialog = () => {
    //     this.setState({visible: false})
    // }

    // onCloseTipoDeRecebimentoDialog = () => {
    //     this.setState({visible: true})
    // }

    render () {

        const editTipoRecebimentoDialogFooter = (
            <DialogFooter save = {this.callSave} hideDialog = {this.hideDialog} disabled={this.props.disabled}/>
        )

        const renderInputChooseContaContabil = () => {
            // if(this.state.selectedTipoDeRecebimentoOuPagamentoLabel.includes(ConstantsUtil.avulsoTipoDeRecebimentoLabel))
            if(
                this.state.selectedTipoDeRecebimentoOuPagamentoLabel.includes(ConstantsUtil.avulsoTipoDeRecebimentoLabel)
                ||
                this.state.selectedTipoDeRecebimentoOuPagamentoLabel.includes(ConstantsUtil.avulsoTipoDePagamentoLabel)
            )
            return (
                <>
                <InputChooseContaContabil
                    noHTMLFormat
                    selectedContaContabilLabel={this.state.selectedContaContabilLabel}
                    tipoLancamentoBancario={this.state.tipoLancamento}
                    handleSelectionContaContabilChange={this.handleSelectionContaContabilChange}
                    inputContaContabilErrorClass={this.state.inputContaContabilErrorClass}
                    errorContaContabilMessage={this.state.errorContaContabilMessage}
                />

                <br />

                </>
            )
        }

        const renderHistoricoTextArea = () => {
            if(
                this.state.selectedTipoDeRecebimentoOuPagamentoLabel.includes(ConstantsUtil.avulsoTipoDeRecebimentoLabel)
                ||
                this.state.selectedTipoDeRecebimentoOuPagamentoLabel.includes(ConstantsUtil.avulsoTipoDePagamentoLabel)
            )
            return(
                <>
                <div className="p-field">
                <label htmlFor="icon">Histórico</label>
                    <InputTextarea
                        className={this.state.inputHistoricoErrorClass}
                        value={this.state.historico}
                        onChange={(e) => this.setState({historico: e.target.value})}
                        onKeyDown={this.handleKeyPress}
                        rows={5} cols={35}
                        autoResize
                        // disabled={this.state.selectedTipoDeRecebimentoOuPagamentoLabel !== ConstantsUtil.avulsoTipoDeRecebimentoLabel}
                    />
                    <small id="historicoError" className="p-error">{this.state.errorHistoricoMessage}</small>
                </div>
                </>
            )
        }

        const renderComplementoDeHistorico = () => {
            if(
                (
                    this.state.tipoLancamento === ConstantsUtil.lancamentoBancarioPagamentoLabel
                    &&
                    this.state.selectedTipoDeRecebimentoOuPagamentoLabel !== ConstantsUtil.avulsoTipoDePagamentoLabel
                )
                ||
                (
                    this.state.tipoLancamento === ConstantsUtil.lancamentoBancarioRecebimentoLabel
                    &&
                    this.state.selectedTipoDeRecebimentoOuPagamentoLabel === ConstantsUtil.naoIdentificadoLabel
                )
            ){
                return (
                    <div className="p-field">
                    <label htmlFor="icon">Complemento de Histórico (Opcional)</label>
                    <InputTextarea
                        ref={this.complementoHistoricoRef}
                        className={this.state.inputComplementoHistoricoErrorClass}
                        value={this.state.complementoHistorico}
                        onChange={(e) => this.setState({complementoHistorico: e.target.value})}
                        rows={5} cols={35}
                        autoResize
                    />
                    </div>
                )
            }
        }        

        const renderContaBancariaContraPartidaInput = () => {
            if(this.isTipoDeLancamentoTransferencia())
            return (
                <>
                <div className="p-field">
                   <InputChooseContaBancaria
                        label={GeneralServices.getContaBancariaOrigemOuDestinoLabel(false, this.isTipoDeLancamentoTransferencia, this.isTipoDeLancamentoTransferenciaEntrada)}
                        readOnly
                        excludeAplicacaoFinanceira
                        // autofocus
                        // forceShowDialog={this.state.forceShowContaBancariaDialog}
                        // doNotForceContaBancariaDialog={this.doNotForceContaBancariaDialog}
                        selectedContaBancariaLabel={this.state.selectedContaBancariaContraPartidaLabel}
                        handleSelectionContaBancariaChange={this.handleSelectionContaBancariaContraPartidaChange}
                        inputContaBancariaErrorClass={this.state.inputContaBancariaContraPartidaLabelErrorClass}
                        noHTMLFormat
                    />
                </div>
                </>
                    
            )
        }

        const renderInputTipoDeRecebimentoOuPagamento = () => {
            if(!this.props.state){
                return
            }

            if(this.state.tipoDeLancamentoCompleto === ConstantsUtil.lancamentoBancarioRecebimentoLabel){
                return (
                    <InputChooseTipoDeRecebimento
                        selectedTipoDeRecebimentoLabel={this.state.selectedTipoDeRecebimentoOuPagamentoLabel}
                        tipoDeRecebimentoList={this.props.tipoDeRecebimentoList}
                        // tipoDeRecebimentoList={this.filterTipoDeRecebimento()}
                        contaBancariaId={this.state.idSelectedContaBancaria}
                        noHTMLFormat
                        handleSelectionTipoDeRecebimentoChange={this.handleSelectionTipoDeRecebimentoChange}
                        inputTipoDeRecebimentoErrorClass={this.state.inputTipoDeRecebimentoErrorClass}
                        errorTipoDeRecebimentoMessage={this.state.errorTipoDeRecebimentoMessage}
                        fromOFX={this.props.fromOFX}
                        // onRenderDialog={this.onRenderTipoDeRecebimentoDialog}
                        // onCloseDialog={this.onCloseTipoDeRecebimentoDialog}
                    />
                )
            } else if(this.state.tipoDeLancamentoCompleto === ConstantsUtil.lancamentoBancarioPagamentoLabel){
                return (
                    <InputChooseTipoDePagamento
                        selectedTipoDePagamentoLabel={this.state.selectedTipoDeRecebimentoOuPagamentoLabel}
                        noHTMLFormat
                        // renderDialogOnMount
                        // forceShowDialog={this.state.forceShowTipoDePagamentoDialog}
                        // hideDialog={this.hideTipoDePagamentoDialog}
                        // tipoDePagamentoList={this.props.tipoDePagamentoList}
                        contaBancariaId={this.state.idSelectedContaBancaria}
                        handleSelectionTipoDePagamentoChange={this.handleSelectionTipoDePagamentoChange}
                        inputTipoDePagamentoErrorClass={this.state.inputTipoDePagamentoErrorClass}
                    />
                )
            }
        }

        const renderCheckBoxMarcarTipoDeRecebimentoOuPagamento = () => {
            if(this.props.fromOFX){
                return (
                    <>
                    <br />
                    <CheckBoxComponent
                        label="Gravar Escolha Acima?"
                        checked={this.state.gravarEscolha}
                        handleCheckBoxChange={(checked) => this.setState({gravarEscolha: checked})}
                    />
                    {/* <br /> */}
                    </>
                )
            }
        }

        return (
            <>
            <Dialog
                visible={this.props.visible && this.state.visible}
                style={{ width: '450px' }}
                header={this.props.header}
                modal
                className="p-fluid"
                footer={editTipoRecebimentoDialogFooter}
                onHide={this.hideDialog}
            >

                <div className="p-field">
                    <CustomCalendar
                        id="lancamentoDate"
                        htmlFor="dataLancamentoInicio"
                        label="Data"
                        inputDateErrorClass={this.state.inputDateErrorClass}
                        value={this.state.dataLancamentoView}
                        name="dataLancamento"
                        onChange={this.handleDateChange}
                        noHTMLFormat
                        // disabled={!this.state.habilitaBusca}
                        errorDateMessage={this.state.errorDateMessage}
                    />
                </div>

                <br />

                <div className="p-field">
                    <FormGroup label = "Tipo de Lançamento " htmlFor = "InputTipoLancamento">
                        <SelectMenu
                        id="selectTipoLancamento"
                        className={"form-control " + this.state.inputTipoDeLancamentoErrorClass}
                        // ref={this.tipoDeLancamentoInputRef}
                        name="tipoLancamento"
                        list={this.lancarFechamentoBancarioService.tipoDeLancamentoList} 
                        value={this.state.tipoDeLancamentoCompleto}
                        onChange={this.handleTipoDeLancamentoChange}
                        // disabled={this.props.fromOFX}
                        /> 
                        <div className="invalid-feedback">{this.state.errorTipoDeLancamentoMessage}</div>  
                    </FormGroup> 
                </div>

                <div className="p-field">
                    {renderInputTipoDeRecebimentoOuPagamento()}

                    <br />

                    {renderContaBancariaContraPartidaInput()}

                    <div className='col-md-12'>
                        {renderCheckBoxMarcarTipoDeRecebimentoOuPagamento()}
                    </div>                    

                </div>

                <br />
                
                <div className="p-field">
                <label htmlFor="valor">Valor do Lançamento</label>
                <InputNumber 
                    className={this.state.inputValorErrorClass}
                    name="valor"
                    inputId="valorFechamentoBancario"
                    value={this.state.valor}
                    onValueChange={this.handleValorChange}
                    onKeyDown={this.handleKeyPress}
                    // mode="currency" currency="BRL" locale="pt-BR"
                    mode="decimal" locale="pt-BR" minFractionDigits={2}
                />
                <small id="valorError" className="p-error">{this.state.errorValorMessage}</small>
                </div>

                <br />
                
                {renderInputChooseContaContabil()}

                {renderHistoricoTextArea()}

                {renderComplementoDeHistorico()}
                

            </Dialog>
            
            </>

        )
    }
}

export default EditLancamentoBancarioDialog