import React from 'react'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import GeneralServices from '../../../../app/service/generalServices'
import { Button } from 'primereact/button'
import { classNames } from 'primereact/utils'
import * as xlsx from 'xlsx'
import ConstantsUtil from '../../../../context/constantsUtil'
import DREFichaBancariaDialog from '../DREFichaBancariaDialog'


class DREDiferencaConsolidadoTable extends React.Component {

    state = {
        
        loading: false,

        elementToView: '',
        displayFichaBancariaDialog: false,

        forceUpdateDialog: false,


    }

    getTableHeaders = () => {
        
        const periodo = this.props.initialDate ? this.props.initialDate + " - " + this.props.finalDate : ""
        const periodoLabel = "Período: " + periodo

        return {periodo: periodoLabel}
    }

    // saldoClass(rowData) {
    //     var obj ={}
    //     if(rowData.total > 0){
    //         obj = {
    //             'superavit': true
    //         }
    //     }
    //     else if(rowData.total < 0){
    //         obj = {
    //             'deficit': true
    //         }
    //     }

    //     return obj
    // }

    formattedDREMensalDiferencaToExportByExcel(){

        var obj = {'': this.props.header}

        const diferenca = JSON.parse(JSON.stringify(this.props.dreConsolidado.diferenca))

        this.props.dreConsolidado.descricaoMesesList.forEach(descricaoMes =>{
            

            obj[descricaoMes] = diferenca.diferencaMap[descricaoMes]
        })

        obj.Total = diferenca.total

        var formattedList = [obj]

        return formattedList
    }

    generateWorkSheet(){
        const worksheet = GeneralServices.jsonToSheet(this.formattedDREMensalDiferencaToExportByExcel());
        return worksheet
    }

    exportExcel = () => {
        const periodo = this.props.initialDate + " - " + this.props.finalDate
        const worksheet = this.generateWorkSheet()
        const sheetName = periodo
        const workbook = { Sheets: { [sheetName]: worksheet }, SheetNames: [[sheetName]] };
        this.props.saveAsExcelFile(workbook, `Fluxo de Caixa Mensal ${this.props.header} - ${periodo} - VisãoRJ`);
    }

    saveAsExcelFile(buffer, fileName) {
        import('file-saver').then(module => {
            if (module && module.default) {
                let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
                let EXCEL_EXTENSION = '.xlsx';
                const data = new Blob([buffer], {
                    type: EXCEL_TYPE
                });

                module.default.saveAs(data, fileName + EXCEL_EXTENSION);
            }
        });
    }

    viewLancamentos = async (diferenca, descricaoMes) => {
        
        this.setState({elementToView: diferenca.diferencaMap[descricaoMes]})
        this.setState({displayFichaBancariaDialog: true})
        this.setState({forceUpdateDialog: true})

    }

    hideDialog = () => {
        this.setState({displayFichaBancariaDialog: false})
    }

    render (){

        const renderMesesColumn = () => {
            var mesesColumnList = []

            if(this.props.dreConsolidado && this.props.dreConsolidado.descricaoMesesList && this.props.dreConsolidado.descricaoMesesList.length !== 0){
                this.props.dreConsolidado.descricaoMesesList.forEach(descricaoMes => {
                    mesesColumnList.push(renderColumnItem(descricaoMes))
                });
            }

            return mesesColumnList
        }

        const renderColumnItem = (descricaoMes) => {
            return (
                <Column
                    field={descricaoMes}
                    header={descricaoMes}
                    // style ={ {width: ConstantsUtil.widthColumDreConsolidado} }
                    style = { {width: GeneralServices.porcentagemColunaTabelaMesesConsolidados(this.props.qtdMeses, ConstantsUtil.tipoColunaMes)} }                    
                    body={diferenca => {
                        // return (renderColumn(diferenca.diferencaMap[descricaoMes]))
                        return (renderColumn(diferenca, descricaoMes))
                     }
                    }
                    // sortable
                />
            )
        }


        const renderColumn = (diferenca, descricaoMes) => {

            return(
                <div className='right-align'>
                {renderViewLancamentosLink(diferenca, descricaoMes)}
                </div>
            )
        }

        const renderViewLancamentosLink = (diferenca, descricaoMes) => {
            // const cond1 = dreDestacadoDTO.dreMensalConsolidadoMap[descricaoMes] && dreDestacadoDTO.dreMensalConsolidadoMap[descricaoMes].fichaBancariaUnificada.fichaBancariaList && dreDestacadoDTO.dreMensalConsolidadoMap[descricaoMes].fichaBancariaUnificada.fichaBancariaList.length > 0
            const cond1 = diferenca.diferencaMap[descricaoMes] && diferenca.diferencaMap[descricaoMes].total !== 0
            const cond2 = diferenca.tipoLancamento !== ConstantsUtil.tipoTotaisFichaBancariaLabel

            const mesValor = diferenca.diferencaMap[descricaoMes].total

            if( cond1 && cond2 ){
                const classes = classNames({
                    'superavitValue': mesValor >= 0,
                    'deficitValue': mesValor <0
                });

                return(
                    <a 
                        className={classes}
                        // href='/DREConsolidado'
                        onClick={() => this.viewLancamentos(diferenca, descricaoMes)}
                    >
                        {mesValor ? GeneralServices.showFormattedIfNotNull(mesValor, true) : ConstantsUtil.valueFormattedZero}
                    </a>
                )
            }
            else{
                return(
                    <>
                        {mesValor ? GeneralServices.showFormattedIfNotNull(mesValor, true) : ConstantsUtil.valueFormattedZero}
                    </>
                )
            }
        }        


        const renderStyledValue = (valor) => {

            const classes = classNames({
                'superavitValue': valor >= 0,
                'deficitValue': valor<0
            });
    
            return (
                <div className={classes}>
                    {GeneralServices.showFormattedIfNotNull(valor, true)}
                </div>
            );
        }


        return (
            <>
            <div className = "card-header header-small-height" >
            <div className='col-md-12'>
                
                <div className='row'>
                    <div className='col-md-4' style={{marginLeft: '1.05rem'}}>
                        <h5 className='small-font-size'>{this.props.header}</h5>            
                    </div>
                    <div className='col-md-4'>
                        {/* <h5 className='small-font-size'>{this.getTableHeaders().periodo}</h5> */}
                    </div>
                    <div className='col-md-2'>
                    </div>
                </div>
                
                
            </div>
            </div>

            
            <DataTable
                ref={this.dt}
                value={[this.props.dreConsolidado.diferenca]}
                className="p-datatable-sm small-font"
                // className="p-datatable-responsive-demo"
                // rowClassName={this.saldoClass}
                rowHover
                showGridlines
                // selection={this.state.selectedContasBancaria}
                // onSelectionChange={this.handleSelectionChange}
                // scrollable
                // scrollHeight="500px"
                style={{ maxWidth: '100vw' }}
                frozenWidth={ConstantsUtil.widthColumNomeDreConsolidado}
                loading={this.props.loading || this.state.loading}
                dataKey="id"
                emptyMessage="Nenhum Resultado encontrado."
                // paginator rows={ConstantsUtil.initialTableQuantityOption}
                // paginatorTemplate={GeneralServices.tablePaginatorTemplate(ConstantsUtil.initialTableQuantityOption, this.props.list ? this.props.list.length : '')}
                // currentPageReportTemplate="Mostrando de {first} ao {last} de {totalRecords} Tipos de Pagamento"
            >

            <Column field="nome" header="Nome"
                // style ={ {width: ConstantsUtil.widthColumNomeDreConsolidado} }
                style = { {width: GeneralServices.porcentagemColunaTabelaMesesConsolidados(this.props.qtdMeses, ConstantsUtil.tipoColunaNome)} }
                frozen
                body = {rowData => "DIFERENÇA"}
            />

            {renderMesesColumn()}

            <Column field="total" header="Total"
                // style ={ {width: ConstantsUtil.widthColumDreConsolidado} }
                style = { {width: GeneralServices.porcentagemColunaTabelaMesesConsolidados(this.props.qtdMeses, ConstantsUtil.tipoColunaMes)} }
                body = {rowData => {
                    return (
                        <div className='right-align'>
                            {rowData ? renderStyledValue(rowData.total) : ''}
                        </div>
                    )
                }}
            />

            <Column style = { {width: GeneralServices.porcentagemColunaTabelaMesesConsolidados(this.props.qtdMeses, ConstantsUtil.tipoColunaPorcentagem)} } />

            </DataTable>

            <DREFichaBancariaDialog
                visible={this.state.displayFichaBancariaDialog}
                initialDate={this.state.elementToView ? this.state.elementToView.descricaoMes.initialDate : ''}
                finalDate={this.state.elementToView ? this.state.elementToView.descricaoMes.finalDate : ''}
                // element={this.state.elementToView}
                diferencaDRE
                unificarEmpresas={this.props.unificarEmpresas}
                selectedEmpresas={this.props.selectedEmpresas}
                forceUpdate={this.state.forceUpdateDialog}
                doneForceUpdate={() => this.setState({forceUpdateDialog: false})}
                // recebimento={this.state.fichaBancariaRecebimento}
                saveAsExcelFile={this.props.saveAsExcelFile}
                loadingTable={false}
                hideDialog={this.hideDialog}
                push={this.props.push}
            />            


            </>
        )
    }


}  

export default DREDiferencaConsolidadoTable