import React from 'react'
import { withRouter } from 'react-router-dom'
import { AuthContext } from '../../../main/authProvider'
import NFService from '../../../app/service/estoque/NFService'
import HandleErrorService from '../../../app/service/handleErrorService'
import GeneralServices from '../../../app/service/generalServices'
import * as popUp from '../../../components/toastr'
import ConstantsUtil from '../../../context/constantsUtil'
import DateIntervalCalendar from '../../../components/calendar/dateIntervalCalendar'
import Card from '../../../components/card'
import { Button } from 'primereact/button'
import { InputSwitch } from 'primereact/inputswitch'
import EmpresaService from '../../../app/service/central/empresaService'
import LancamentoFechamentoCaixaService from '../../../app/service/contasAReceber/lancamentoFechamentoCaixaService'
import PDVLancamentoService from '../../../app/service/contasAReceber/pdvLancamentoService'
import TooltipButton from '../../../components/tooltipButton'

class SyncApisExternas extends React.Component {

    constructor(){
        super()
        this.nfService = new NFService()
        this.generalServices = new GeneralServices()
        this.empresaService = new EmpresaService()
        this.lancamentoFechamentoCaixaService = new LancamentoFechamentoCaixaService()
        this.pdvLancamentoService = new PDVLancamentoService()
    }

    state = {
        
        selectedDateFilterType: '',
        missingFilterType: false,
        missingAnyDate: false,
        initialDate: '',
        initialDateView: '',
        finalDate: '',
        finalDateView: '',
        
        habilitaBusca: true,
        loading: false,

        listaAdministradorasCartaoIntegradasMessage: '',

        syncAllEmpresas: false,

    }

    componentDidMount(){
        switch(window.location.pathname){
            case ConstantsUtil.syncImportacaoNFsVendaPathname:
                break
                
            case ConstantsUtil.buscarFechamentoDeCaixaAdministradorasPathname:
                this.searchIntegracoesAdministradorasCartao()
                break

            case ConstantsUtil.calcularPDVPathname:
                break
        }  
    }

    handleDateFilterChange = (selectedDateFilterType) => {
        this.setState({selectedDateFilterType})
    }

    handleDateChange = async (initialDate, finalDate) => {
        await this.setState({initialDate})
        // console.log("handleDateChange initial: ", this.state.initialDate)
        
        await this.setState({initialDateView: GeneralServices.convertBrStringToJsDate(initialDate)})
        // console.log("handleDateChange initialView: ", this.state.initialDateView)

        await this.setState({finalDate})
        await this.setState({finalDateView: GeneralServices.convertBrStringToJsDate(finalDate)})

    }

    resetView = () => {

        this.setState({missingFilterType: false})
        this.setState({missingAnyDate: false})

        this.setState({inputEmpresasErrorClass: ''})
        this.setState({errorEmpresasErrorMessage: ''})

    }
    
    checkData = () => {

        var check = true

        if(!this.state.selectedDateFilterType){
            this.setState({missingFilterType: true})
            check=false
        }
        
        if(!this.state.initialDate || !this.state.finalDate){
            this.setState({missingAnyDate: true})
            check=false
        }

        return check
    }
    
    handleSync = () => {
        switch(window.location.pathname){
            case ConstantsUtil.syncImportacaoNFsVendaPathname:
                this.syncNFsVenda()
                break;
                
            case ConstantsUtil.buscarFechamentoDeCaixaAdministradorasPathname:
                this.buscarFechamentoCaixaAdministradoras()
                break;
                
            case ConstantsUtil.calcularPDVPathname:
                this.calcularPDV()
                break;
        }  
    }

    callSync = () => {

        this.resetView()

        if(this.checkData()){
            this.handleSync()
        }

    }

    searchIntegracoesAdministradorasCartao = () => {
        this.lancamentoFechamentoCaixaService.searchIntegracoesAdministradorasCartao()
        .then(response => {
            this.trataListaAdministradorasIntegradas(response.data)
        })
        .catch(error => {
            HandleErrorService.handleError(error)
        })
    }

    trataListaAdministradorasIntegradas = (listaAdministradorasIntegradas) => {
        let message = ""
        listaAdministradorasIntegradas.forEach(administradoraIntegrada => {
            message += `\n- ${administradoraIntegrada}`
        })
        this.setState({listaAdministradorasCartaoIntegradasMessage: message})
    }

    handleBuscarFechamentoCaixaAdministradoras = () => {
        if(this.state.syncAllEmpresas){
            // return this.empresaService.runSyncNFsVenda(this.state.initialDate, this.state.finalDate)
        }
        else{
            return this.lancamentoFechamentoCaixaService.buscarDasAdministradoras(this.state.initialDate, this.state.finalDate)
        }        
    }

    buscarFechamentoCaixaAdministradoras = () => {
        this.setState({habilitaBusca: false})
        this.handleBuscarFechamentoCaixaAdministradoras()
        .then(async response => {
            popUp.successPopUp("Importação de caixa realizada com sucesso!")

        })
        .catch(async error => {
            HandleErrorService.handleError(error)
        })
        .finally(() => this.setState({habilitaBusca: true}))
    }

    handleCalcularPDV = () => {
        if(this.state.syncAllEmpresas){
            // return this.empresaService.runSyncNFsVenda(this.state.initialDate, this.state.finalDate)
        }
        else{
            return this.pdvLancamentoService.calcularPDV(this.state.initialDate, this.state.finalDate)
        }        
    }

    calcularPDV = () => {
        this.setState({habilitaBusca: false})
        this.handleCalcularPDV()
        .then(async response => {
            popUp.successPopUp("PDV calculado com sucesso!")

        })
        .catch(async error => {
            HandleErrorService.handleError(error)
        })
        .finally(() => this.setState({habilitaBusca: true}))
    }

    handleSyncNFsVenda = () => {
        if(this.state.syncAllEmpresas){
            return this.empresaService.runSyncNFsVenda(this.state.initialDate, this.state.finalDate)
        }
        else{
            return this.nfService.syncNFsVenda(this.state.initialDate, this.state.finalDate)
        }
    }

    syncNFsVenda = () => {
        this.setState({habilitaBusca: false})
        this.handleSyncNFsVenda()
        .then(async response => {
            popUp.successPopUp("Sincronização realizada com sucesso!")
            // await this.generalServices.sleep(1000)
            // window.location = ConstantsUtil.homePathname

        })
        .catch(async error => {
            // console.log(error.response)
            HandleErrorService.handleError(error)
            // await this.generalServices.sleep(1000)
            // window.location = ConstantsUtil.homePathname
        })
        .finally(() => this.setState({habilitaBusca: true}))
    }

    getDataInicial = () => {
        
        let dataInicial = new Date()
        
        switch(window.location.pathname){
            case ConstantsUtil.syncImportacaoNFsVendaPathname:
                dataInicial.setDate(dataInicial.getDate() - 3)
                return dataInicial;
                
            case ConstantsUtil.buscarFechamentoDeCaixaAdministradorasPathname:
                dataInicial.setDate(dataInicial.getDate() - 1)
                return dataInicial;
                
            case ConstantsUtil.calcularPDVPathname:
                dataInicial.setDate(dataInicial.getDate() - 1)
                return dataInicial;

        }        

    }

    getDataFinal = () => {
        
        let dataInicial = new Date()

        switch(window.location.pathname){
            case ConstantsUtil.syncImportacaoNFsVendaPathname:
                return dataInicial;
                
            case ConstantsUtil.buscarFechamentoDeCaixaAdministradorasPathname:
                dataInicial.setDate(dataInicial.getDate() - 1)
                return dataInicial;

            case ConstantsUtil.calcularPDVPathname:
                dataInicial.setDate(dataInicial.getDate() - 1)
                return dataInicial;
        }        

    }

    getPageLabel = () => {
        switch(window.location.pathname){
            case ConstantsUtil.syncImportacaoNFsVendaPathname:
                return "Sincronização de NFs de Venda"
                
            case ConstantsUtil.buscarFechamentoDeCaixaAdministradorasPathname:
                return "Buscar Fechamento de Caixa das Administradoras"
                
            case ConstantsUtil.calcularPDVPathname:
                return "Calcular PDV"
        }
    }

    getUnificarButtonLabel = () => {
        switch(window.location.pathname){
            case ConstantsUtil.syncImportacaoNFsVendaPathname:
                return 'Sincronizar em todas as empresas?'
                
            case ConstantsUtil.buscarFechamentoDeCaixaAdministradorasPathname:
                return 'Buscar para todas as empresas?'

            case ConstantsUtil.calcularPDVPathname:
                return 'Calcular em todas as empresas?'
        }        
    }

    getButtonLabel = () => {
        switch(window.location.pathname){
            case ConstantsUtil.syncImportacaoNFsVendaPathname:
                return 'Sincronizar'
                
            case ConstantsUtil.buscarFechamentoDeCaixaAdministradorasPathname:
                return 'Buscar'
                
            case ConstantsUtil.calcularPDVPathname:
                return 'Calcular'
        }  
    }

    getButtonIcon = () => {
        switch(window.location.pathname){
            case ConstantsUtil.syncImportacaoNFsVendaPathname:
                return "pi pi-link"
                
            case ConstantsUtil.buscarFechamentoDeCaixaAdministradorasPathname:
                return "pi pi-search"

            case ConstantsUtil.calcularPDVPathname:
                return "pi pi-chart-bar"
        }  
    }

    montaMensagemInfoIntegracaoAdministradoras = () => {
        if(this.state.listaAdministradorasCartaoIntegradasMessage){
            return `Esta empresa possui integração com a(s) seguinte(s) administradora(s) de cartão:${this.state.listaAdministradorasCartaoIntegradasMessage}`
        }
        return "Esta empresa não possui integração com nenhuma administradora de cartão."
    }

    render(){

        const renderBody = () => {
            return (
                <>
                <div className = "col-md-12">
                    <div className="row">
                    <DateIntervalCalendar
                        htmlFor="SelectDatePeriod"
                        // defaultValue={ConstantsUtil.calendarPersonalizadoFilterLabel}
                        handleFilterChange={this.handleDateFilterChange}
                        handleDateChange={this.handleDateChange}
                        missingFilterType={this.state.missingFilterType}
                        missingAnyDate={this.state.missingAnyDate}
                        disabled={!this.state.habilitaBusca}
                        preSetFilterType={ConstantsUtil.calendarPersonalizadoFilterLabel}
                        preSetDataInicial={this.getDataInicial()}
                        preSetDataFinal={this.getDataFinal()}
                        enableChangeFilterType
                    />

                    <div className = "col-md-3">
                        <h6>{this.getUnificarButtonLabel()}</h6>
                        <InputSwitch 
                            checked={this.state.syncAllEmpresas}
                            onChange={event => this.setState({syncAllEmpresas: event.value})}
                            disabled={
                                !this.state.habilitaBusca
                                || (window.location.pathname !== ConstantsUtil.syncImportacaoNFsVendaPathname
                                    // && window.location.pathname !== ConstantsUtil.calcularPDVPathname
                                )
                            }
                        />
                    </div>

                    <div
                        style={{
                            marginTop: '0.3rem', marginLeft: '0.5rem', marginRight: '0.5rem'
                        }}
                        className = "col-md-1.5"
                    >
                    </div>

                    </div>
                </div>
                </>
            )
        }

        const renderButtons = () => {
            if(this.state.habilitaBusca){
                return (
                    <Button 
                        label={this.getButtonLabel()}
                        className='small-button'
                        icon={this.getButtonIcon()}
                        onClick = {this.callSync}
                        // style={ {maxHeight: '35px' } }
                        disabled = {this.state.isSearchingLancamentos}
                    />
                )
            }
            return (
                <>
                    <Button 
                        label="Sincronizando..."
                        icon="pi pi-undo"
                        className="small-button"
                        disabled
                        // onClick = {this.toggleButton}
                        // onClick = {() => this.recebimentoRef.current.exportExcel()}
                        // style={ {maxHeight: '35px' } }
                        // disabled = {this.state.isSearchingLancamentos}
                    />
                </>
            )
        }

        const renderTooltipButton = () => {
            switch(window.location.pathname){
                case ConstantsUtil.syncImportacaoNFsVendaPathname:
                    return <></>
                    
                case ConstantsUtil.buscarFechamentoDeCaixaAdministradorasPathname:
                    return (
                        <TooltipButton
                            tooltip={this.montaMensagemInfoIntegracaoAdministradoras()}
                            tooltipOptions={{
                                className: 'custom-tooltip',
                                position: 'left'
                            }}
                            icon={<i className="pi pi-question" style={{fontSize: '0.8em'}}></i>}
                            style ={{maxHeight: '25px', maxWidth: '25px'}}
                            className="p-button-rounded p-mr-2"
                        />
                    )
    
                case ConstantsUtil.calcularPDVPathname:
                    return (
                        <TooltipButton
                            tooltip={"Calcula o PDV com base nas notas fiscais de saída do período selecionado."}
                            tooltipOptions={{
                                className: 'custom-tooltip',
                                position: 'left'
                            }}
                            icon={<i className="pi pi-question" style={{fontSize: '0.8em'}}></i>}
                            style ={{maxHeight: '25px', maxWidth: '25px'}}
                            className="p-button-rounded p-mr-2"
                        />
                    )
            }  
        }

        return(
            <div className="bs-docs-section " >
                <Card
                    title = {this.getPageLabel()}
                    rightComponent={renderTooltipButton()}
                >
                    
                    <div className = "col-md-12">

                    <div className="row">

                        {renderBody()}

                    </div>  

                    <div
                        style={{marginTop: '0.5rem', marginBottom: '0.5rem'}}
                    >
                        {renderButtons()}
                    </div>
                    
                    </div> {/* col-md-12 */}

                </Card>
                <div className="d-flex "/>
            </div>

        )
    }


}

SyncApisExternas.contextType = AuthContext

export default withRouter(SyncApisExternas)