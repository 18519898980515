import React from 'react'
import { Toast } from 'primereact/toast'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'

import GeneralServices from '../../app/service/generalServices'
import TableFilters from '../../components/tableFilters'
import ConstantsUtil from '../../context/constantsUtil'
import { Toolbar } from 'primereact/toolbar'
import { Button } from 'primereact/button'
import { classNames } from 'primereact/utils'
import DetalhamentoMovimentacaoProductDialog from '../../components/product/detalhamentoMovimentacaoProductDialog'

class TransactionByProductTable extends React.Component {

    state = {
        filteredList: null,

        displayDetalhamentoDialog: false,
        productDetalhamento: null,
    }

    constructor(){
        super()
        this.dt = React.createRef()
        this.tableFilters = new TableFilters();

    }

    onFilterChange = (currentList) => {
        this.setState({filteredList: currentList})
    }

    getList = () => {
        return this.state.filteredList ? this.state.filteredList : this.props.list;
    }

    getListToExport = () => {
        let exportList = this.getList().map(item => {
            return {
                ['Código']: item.product.codigo,
                ['Produto']: item.product.descricao,
                ['Quantidade']: `${item.quantidade} UN`,
                ['Valor Total']: item.valor
            }
        })

        exportList.push({
            ['Código']: '',
            ['Produto']: '',
            ['Quantidade']: '',
            ['Valor Total']: ''
        })

        exportList.push({
            ['Código']: 'Total',
            ['Produto']: `${this.getList().length} Itens`,
            ['Quantidade']: `${this.getQuantidadeTotal()} UN`,
            ['Valor Total']: this.getValorTotal()
        })

        return exportList
    }

    getQuantidadeTotal = () => {
        let list = this.getList()
        let quantidadeTotal = 0
        list.forEach(item => quantidadeTotal += item.quantidade)
        return GeneralServices.formatTo2CasasDecimais(quantidadeTotal)
    }

    getValorTotal = () => {
        let list = this.getList()
        let valorTotal = 0
        list.forEach(item => valorTotal += item.valor ? item.valor : 0)
        return GeneralServices.formatTo2CasasDecimais(valorTotal)
    }

    getFilenameToExport = () => {
        return `Vendas_de_${this.props.initialDate}_a_${this.props.finalDate}`
    }

    exportXLSX = () => {
        // console.log("initialDate: ", this.props.initialDate)
        
        let exportList = this.getListToExport()
        GeneralServices.exportExcel(exportList, "Vendas", this.getFilenameToExport())

    }

    exportPDF = () => {
        let columns = [
            {field:['Código'], header:"Código"},
            {field:['Produto'], header:"Produto"},
            {field:['Quantidade'], header:"Quantidade"},
            {field:['Valor Total'], header:"Valor"}
        ]
        let exportColumns = columns.map(col => ({ title: col.header, dataKey: col.field }))

        let exportList = this.getListToExport()
        GeneralServices.exportPdf(this.getFilenameToExport(), exportColumns, exportList)
    }

    viewDetalhamentoQuantidade = async (rowData) => {
        // console.log("viewDetalhamentoQuantidade: ", rowData)
        this.setState({displayDetalhamentoDialog: true})
        this.setState({productDetalhamento: rowData.product})
    }

    hideDetalhamentoDialog = () => {
        this.setState({displayDetalhamentoDialog: false})
        this.setState({productDetalhamento: null})
    }    

    render() {

        //codigoFilterElement Filter
        const codigoFilterElement = this.tableFilters.renderTableFilter(
            this.dt,
            this.state.selectedCodigo,
            this.props.list,
            "selectedCodigo",
            (name, value) => this.setState({[name]: value}),
            'product.codigo',
            '100%',
            "Código"
        )

        //descricaoFilterElement Filter
        const descricaoFilterElement = this.tableFilters.renderTableFilter(
            this.dt,
            this.state.selectedDescricao,
            this.props.list,
            "selectedDescricao",
            (name, value) => this.setState({[name]: value}),
            'product.descricao',
            '100%',
            "Descrição"
        )

        //nomeFornecedorFilterElement Filter
        const nomeFornecedorFilterElement = this.tableFilters.renderTableFilter(
            this.dt,
            this.state.selectedNomeFornecedor,
            this.props.list,
            "selectedNomeFornecedor",
            (name, value) => this.setState({[name]: value}),
            'product.nomeFornecedor',
            '100%',
            "Fornecedor"
        )

        //ncmFilterElement Filter
        const ncmFilterElement = this.tableFilters.renderTableFilter(
            this.dt,
            this.state.selectedNcm,
            this.props.list,
            "selectedNcm",
            (name, value) => this.setState({[name]: value}),
            'product.ncm',
            '100%',
            "NCM"
        )

        //quantidadeFilterElement Filter
        const quantidadeFilterElement = this.tableFilters.renderTableFilter(
            this.dt,
            this.state.selectedQuantidade,
            this.props.list,
            "selectedQuantidade",
            (name, value) => this.setState({[name]: value}),
            'quantidade',
            '100%',
            "Quantidade"
        )

        //valorFilterElement Filter
        const valorFilterElement = this.tableFilters.renderTableFilter(
            this.dt,
            this.state.selectedValor,
            this.props.list,
            "selectedValor",
            (name, value) => this.setState({[name]: value}),
            'valor',
            '100%',
            "Valor"
        )

        const exportHeader = () => {
            return (
                <React.Fragment>
                    
                    <Button
                        label="Exportar"
                        icon="pi pi-file-excel"
                        className="p-button-success small-font small-button"
                        onClick={this.exportXLSX}
                        tooltip={ConstantsUtil.exportXlsxLabel}
                        tooltipOptions={{position: 'top'}}
                    />

                    <Button
                        label="Exportar"
                        icon="pi pi-file-pdf"
                        className="p-button-danger small-font small-button"
                        onClick={this.exportPDF}
                        style={{marginLeft: '10px'}}
                        tooltip={ConstantsUtil.exportPdfLabel}
                        tooltipOptions={{position: 'top'}}
                    />
                </React.Fragment>
            )
        }

        const totaisHeader = () => {
            return (
                <>
                    <div className='col-md-3'>
                    <Button
                        label={`Total: ${GeneralServices.valueBodyTemplate(this.getQuantidadeTotal())} Itens`}
                        className=" small-font small-button"
                    />
                    </div>

                    <div className='col-md-3'>
                    <Button
                        label={`Total: R$ ${GeneralServices.valueBodyTemplate(this.getValorTotal())}`}
                        className=" small-font small-button"
                    />
                    </div>
                </>
            )
        }

        const renderViewQuantidadeLink = (rowData) => {
            let quantidade = rowData.quantidade
            const cond1 = quantidade && quantidade !== 0
            if( cond1 ){
                const classes = classNames({
                    'deficitValue': quantidade < 0,
                    'linkValue': true
                    
                });
                return(
                    <a 
                        className={classes}
                        onClick={() => this.viewDetalhamentoQuantidade(rowData)}
                    >
                        {quantidade}
                    </a>
                )
            }
            else{
                return(
                    <>
                        {GeneralServices.showFormattedIfNonZero(quantidade, false)}
                    </>
                )
            }
        }        

        const renderDetalhamentoDialog = () => {
            if(this.state.displayDetalhamentoDialog)
                return (
                    <DetalhamentoMovimentacaoProductDialog
                        visible={this.state.displayDetalhamentoDialog}
                        tipo={this.props.tipo}
                        product={this.state.productDetalhamento}
                        dataInicial={this.props.initialDate}
                        dataFinal={this.props.finalDate}
                        fromFichaDeEstoque={false}
                        tipoProdutoDetalhamento={this.props.tipoProdutoDetalhamento}
                        filtroTipoData={this.props.filtroTipoData}
                        // tipoAtualizacaoFichaDeEstoque={this.state.fichaDeEstoqueDetalhamento.tipoAtualizacao}
                        // unificar={this.props.unificar}
                        hideDialog={this.hideDetalhamentoDialog}
                    />
                )
        }

        const renderFornecedorColumn = () => {
            if(this.props.tipo === ConstantsUtil.tipoNFEntradaLabel){
                return (
                    <Column
                        field="product.nomeFornecedor"
                        header="Fornecedor"
                        style={ {width: '140px'} }
                        sortable
                        filter filterElement={nomeFornecedorFilterElement}
                    />

                )
            }
        }


        return (
            <div
                className=""
            >
            <div className="card visaorj-small">
                
                <div className='card-header'>
                    <div className='row'>
                        <div className='col-md-6'>
                            {exportHeader()}
                        </div>
                            {totaisHeader()}
                    </div>
                </div>
                
                <DataTable
                    ref={this.dt}
                    value={this.props.list}
                    className="p-datatable-sm small-font"
                    rowHover
                    onValueChange={this.onFilterChange}
                    // showGridlines
                    scrollable
                    scrollHeight="500px"
                    loading={this.props.loading}
                    emptyMessage={ConstantsUtil.nenhumLancamentoEncontradoMessage}
                    dataKey="product.id"
                    paginator rows={ConstantsUtil.initialTableQuantityOption}
                    paginatorTemplate={GeneralServices.tablePaginatorTemplate(ConstantsUtil.initialTableQuantityOption, this.props.list ? this.props.list.length : '')}
                    currentPageReportTemplate="Mostrando de {first} ao {last} de {totalRecords} Lançamentos"
                >

                    <Column
                        header="Código"
                        field="product.codigo"
                        style ={ {width: '30px'} }
                        sortable
                        filter filterElement={codigoFilterElement}
                        body = { (rowData, rowIndex) => GeneralServices.renderBodyColumnInOneLine(rowData.product.codigo, rowIndex)}
                    />

                    <Column
                        field="product.descricao"
                        header="Produto"
                        style={ {width: '140px'} }
                        sortable
                        filter filterElement={descricaoFilterElement}
                    />

                    {renderFornecedorColumn()}

                    <Column
                        header="NCM"
                        style ={ {width: '30px'} }
                        field="product.ncm"
                        filter filterElement={ncmFilterElement}
                    />

                    <Column
                        header="Quantidade"
                        field="quantidade"
                        style ={ {width: '80px'} }
                        sortable
                        filter filterElement={quantidadeFilterElement}
                        body={rowData => {
                            return renderViewQuantidadeLink(rowData)
                        }}                        
                        
                    />

                    <Column
                        header="Valor"
                        field = "valor"
                        style ={ {width: '80px'} }
                        sortable
                        body={rowData => GeneralServices.valueBodyTemplate(rowData.valor)}
                        filter filterElement={valorFilterElement}
                    />

                </DataTable>

                {renderDetalhamentoDialog()}

            </div>

        </div>
        )
    }


}  

export default TransactionByProductTable