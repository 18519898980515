import React from 'react'
import ChooseTipoDeRecebimentoDialog from './chooseTipoDeRecebimentoDialog'

class InputChooseTipoDeRecebimento extends React.Component {

    state = {
        chooseTipoDeRecebimentoDialogVisible: false,

    }

    componentDidMount(){
        if(this.props.renderDialogOnMount){
            this.setState({chooseTipoDeRecebimentoDialogVisible: true})
        }
    }

    hideTipoDeRecebimentoDialog = () => {
        this.setState({chooseTipoDeRecebimentoDialogVisible: false})

        if(this.props.hideDialog){
            this.props.hideDialog()
        }
    }

    handleSelectionTipoDeRecebimentoChange = async (tipoDeRecebimento) => {
        const label = tipoDeRecebimento.nomeTipoDeRecebimento + " - Natureza " + tipoDeRecebimento.natureza
        this.props.handleSelectionTipoDeRecebimentoChange(tipoDeRecebimento, label)

        // if(this.props.onCloseDialog){
        //     this.props.onCloseDialog()
        // }
    }

    handleKeyPress = async (e) => {
        if (e.key === "Enter") {
            this.inputClick()
          }
    }

    inputClick = () => {
        this.setState({chooseTipoDeRecebimentoDialogVisible: true})
        
        // if(this.props.onRenderDialog){
        //     this.props.onRenderDialog()
        // }

    }

    generateLabel = () => {
        var label = "Tipo de Recebimento"

        if(this.props.optional){
            label+=" (Opcional)"
        }

        return label
    }

    render(){

        const renderInput = () => {
            return (
                <>
                <label htmlFor="tipoDeRecebimento">{this.generateLabel()}</label>
                <input style={{color: '#000'}} type="text"
                                className={"form-control " + this.props.inputTipoDeRecebimentoErrorClass}
                                readOnly
                                value = {this.props.selectedTipoDeRecebimentoLabel}
                                name="selectedTipoDeRecebimentoLabel"
                                id="selectedTipoDeRecebimentoLabel"
                                onClick={this.inputClick}
                                onKeyPress={this.handleKeyPress}
                                placeholder="Nenhum Tipo de Recebimento Selecionado..."
                                disabled={this.props.disabled}
                />
                <div className="invalid-feedback">Escolha o Tipo de Recebimento</div> 
                </>
            )
        }

        const renderHTMLFormat = () => {
            if(this.props.noHTMLFormat){
                return (
                    <>
                    {renderInput()}
                    </>
                )
            }
            else {
                return (
                    <>
                    {/* <div className="row"> */}
                    <div className="col-md-4">
                    {renderInput()}
                    </div>
                    {/* </div> */}
                    </>
                )
            }
        }

        const renderDialog = () => {
                return (
                    <ChooseTipoDeRecebimentoDialog
                        header={this.generateLabel()}
                        visible={this.state.chooseTipoDeRecebimentoDialogVisible || this.props.forceShowDialog}
                        list={this.props.tipoDeRecebimentoList}
                        contaBancariaId={this.props.contaBancariaId}
                        hideDialog={this.hideTipoDeRecebimentoDialog}
                        handleSelectionTipoDeRecebimentoChange={this.handleSelectionTipoDeRecebimentoChange}
                        fromOFX={this.props.fromOFX}
                    />
                )
        }

        return(
            <>
                {/* <div className="col-md-4">
                   
                    </div> */}
                {renderHTMLFormat()}

                {renderDialog()}

            </>
        )

    }

}

export default InputChooseTipoDeRecebimento