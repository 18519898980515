import WorkerApiService from "../../workerApiServices";

class ProvisaoService extends WorkerApiService {

    constructor() {
        super('/api/provisao')
    }

    save(object){
        return this.post("/save", object)
    }

    edit(object){
        return this.put("/edit", object)
    }

    deleteById(id){
        return this.delete(`/delete/${id}`)
    }

    searchById(id){
        let params = `?`
        params = `${params}&id=${id}`
        return this.get(`/searchById${params}`)
    }

    searchByNFId(nfId){
        let params = `?`
        params = `${params}&nfId=${nfId}`
        return this.get(`/searchByNFId${params}`)
    }

    searchLancamentos(object){
        return this.post("/searchLancamentos", object)
    }
    
    searchProvisaoProdutos(initialDate, finalDate, tipoProdutoList){
        
        const data = {
            initialDate,
            finalDate,
            tipoProdutoList
        }
            
        // console.log("searchProvisaoProdutos: ", data)
        
        return this.post(`/produtos`, data)
    }

}

export default ProvisaoService