import React from "react";
import TiposDeRecebimentoService from "../../app/service/tiposDeRecebimentoService";
import FormGroup from "../form-group";
import SelectMenu from "../selectMenu";
import TooltipButton from "../tooltipButton";

class DiaDaSemanaSelect extends React.Component {

    constructor(){
        super()
        this.tiposDeRecebimentoService = new TiposDeRecebimentoService()
    }

    render() {

        const diaDaSemanaLabelWithTooltip = () => {
            return(
                <>
                    Dia da Semana
                    <TooltipButton
                        tooltip={`As administradoras de Vouchers utilizam critérios distintos quanto ao dia de corte:
                            - Ticket: Preencha aqui 1 dia da semana anterior ao que consta no portal da administradora.
                            - Demais: Preencha aqui o mesmo dia da semana que consta no portal da administradora.
                        `}
                        tooltipOptions={{
                            className: 'custom-tooltip',
                            position: 'top'
                        }}
                        icon={<i className="pi pi-question" style={{fontSize: '0.8em'}}></i>}
                        style ={{marginLeft: '10px', maxHeight: '25px', maxWidth: '25px'}}
                        className="p-button-rounded p-mr-2"
                    />                    
                </>
            )
        }

        return (
            <FormGroup label = {diaDaSemanaLabelWithTooltip()} htmlFor = {this.props.htmlFor}>
                <SelectMenu
                    className={"form-control " + this.props.inputDiaDaSemanaErrorClass}
                    name="diaDaSemana"
                    list={this.tiposDeRecebimentoService.diaDaSemanaList} 
                    value={this.props.diaDaSemana}
                    onChange={this.props.handleChange}
                    disabled={this.props.disabled}
                />
                <div className="invalid-feedback">{this.props.errorDiaDaSemanaMessage}</div>
            </FormGroup>
        )
    }

}

export default DiaDaSemanaSelect