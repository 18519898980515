import React from 'react'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import GeneralServices from '../../../../app/service/generalServices'
import LancarFechamentoBancarioService from '../../../../app/service/conciliacaoBancaria/lancarFechamentoBancarioService'
import ConstantsUtil from '../../../../context/constantsUtil'
import { Button } from 'primereact/button'
import * as xlsx from 'xlsx'
import DREFichaBancariaDialog from '../DREFichaBancariaDialog'
import { classNames } from 'primereact/utils'
import ExpansionTableButton from '../expansionTableButton'
import TableFilters from '../../../tableFilters'

class DRERecebimentoConsolidadoTable extends React.Component {

    state = {
        
        loading: false,

        forceUpdateDialog: false,
        elementToView: null,
        displayFichaBancariaDialog: false,

        selectedValoresMeses: {},

        expanded: false,

        didUpdate: false,

        tableFilteredList: [],
        primaryFilter: '',

    }

    constructor(){
        super();
        this.toast = React.createRef();
        this.lancarFechamentoBancarioService = new LancarFechamentoBancarioService();
        this.tableFilters = new TableFilters();

    }

    componentDidUpdate(){
        if(!this.state.didUpdate){
            let selectedValoresMeses = {}
            if(this.props.dreConsolidado.descricaoMesesList){
                this.props.dreConsolidado.descricaoMesesList.forEach(descricaoMes => {
                    selectedValoresMeses[descricaoMes] = null
                })
            }
            this.setState({selectedValoresMeses})
            this.setState({didUpdate: true})
            this.setState({tableFilteredList: this.props.dreConsolidado.dreRecebimentoList})

        }
    }

    getTableHeaders = () => {
        
        const periodo = this.props.dreConsolidado && this.props.dreConsolidado.initialDate ? this.props.dreConsolidado.initialDate + " - " + this.props.dreConsolidado.finalDate : ""
        const periodoLabel = "Período: " + periodo

        return {periodo: periodoLabel}
    }

    viewLancamentos = async (rowData, descricaoMes) => {
                
        this.setState({elementToView: rowData.dreMensalConsolidadoMap[descricaoMes]})
        this.setState({forceUpdateDialog: true})
        this.setState({displayFichaBancariaDialog: true})
    }

    hideDialog = () => {
        this.setState({displayFichaBancariaDialog: false})
        this.setState({fichaBancariaToView: ''})
    }

    rowClass(rowData) {
        const obj = {
            [ConstantsUtil.totaisTableLabel] : rowData.tipoLancamento === ConstantsUtil.tipoTotaisFichaBancariaLabel
        }

        return obj
    }

    getValorByContaBancaria(rowData, contaBancaria){
        const list = rowData.dreGrupoContaBancariaList
        const selectedItem = list.find(item => item.contaBancaria.id === contaBancaria.id)
        return GeneralServices.showFormattedIfNotNull(selectedItem.valorTotal, true)
    }

    formattedDREMensalRecebimentoToExportByExcel(){
        var formattedList = []

        this.props.dreConsolidado.dreRecebimentoList.forEach(item =>{

            // console.log("item:", item)
            
            var obj = {
                Recebimento: item.nome,
            }

            this.props.dreConsolidado.descricaoMesesList.forEach(descricaoMes => {
                // console.log("descricaoMes", descricaoMes)
                const mesItem = item.dreMensalConsolidadoMap[descricaoMes]
                // console.log("mesItem: ", mesItem)
                let valor = ''
                if(mesItem){
                    valor = mesItem.total
                }
                else{
                    valor = 0
                }
                obj[descricaoMes] = valor
            })

            obj.Total = item.total
            const porcentagem = '%'
            obj[porcentagem] = `${item.porcentagem}%`
            formattedList.push(obj)
        })

        return formattedList
    }

    generateWorkSheet(){
        const worksheet = GeneralServices.jsonToSheet(this.formattedDREMensalRecebimentoToExportByExcel());
        return worksheet
    }

    exportExcel = () => {
        const periodo = this.props.dreConsolidado.initialDate + " - " + this.props.dreConsolidado.initialDate
        const worksheet = this.generateWorkSheet()
        const sheetName = periodo
        const workbook = { Sheets: { [sheetName]: worksheet }, SheetNames: [[sheetName]] };
        this.props.saveAsExcelFile(workbook, `Fluxo de Caixa Mensal de Recebimento - ${periodo} - VisãoRJ`);
    }

    filterList = (filterColumnName) => {
        // console.log("this.props.dreConsolidado.dreRecebimentoList: ", this.props.dreConsolidado.dreRecebimentoList)
        if(!this.state.expanded && this.props.dreConsolidado && this.props.dreConsolidado.dreRecebimentoList){
            let listToBeReturned = this.props.dreConsolidado.dreRecebimentoList.filter(item => item.tipoLancamento ===  ConstantsUtil.tipoTotaisFichaBancariaLabel)
            this.props.resetTotalItem(listToBeReturned)
            // console.log("filterList: ", listToBeReturned)
            return listToBeReturned
        }
        // console.log("this.props.dreConsolidado.dreRecebimentoList: ", this.props.dreConsolidado.dreRecebimentoList)
        if(filterColumnName && this.state.primaryFilter && (this.state.primaryFilter !== filterColumnName)){
            /*
            Se a coluna que está sendo filtrada é o filtro primário, então envia toda a lista.
            Caso contrário, envia apenas a lista filtrada.
            */
            return this.state.tableFilteredList
        }
        return this.props.dreConsolidado.dreRecebimentoList
    }

    updateValorMesFilter = async (descricaoMes, value) => {
        // console.log("updateValorMesFilter: ", descricaoMes, value)
        let selectedValoresMeses = this.state.selectedValoresMeses
        selectedValoresMeses[descricaoMes] = value

        this.checkPrimaryFilter(descricaoMes)

        await this.setState({selectedValoresMeses})
        // console.log("selectedValoresMeses: ", this.state.selectedValoresMeses)
    }

    checkPrimaryFilter = async (filterColumn) => {
        if(this.state.tableFilteredList.length === this.filterList().length){
            await this.setState({primaryFilter: filterColumn})
            // console.log("primaryFilter: ", this.state.primaryFilter)
        }
    }

    renderMesTableFilter = (descricaoMes) => {
        // console.log("this.tableFilters: ", descricaoMes)
        return (
            <div className='right-align'>
                {this.tableFilters.renderTableFilter(
                    this.props.dt,
                    this.state.selectedValoresMeses[descricaoMes],
                    this.filterList(descricaoMes),
                    descricaoMes,
                    this.updateValorMesFilter,
                    descricaoMes,
                    '100%',
                    "Total"
                )}
            </div>
        )
    }

    onFilterTable = async (currentList) => {
        // console.log("currentList: ", currentList)
        this.props.onFilterTable(currentList, this.props.dt)

        this.setState({tableFilteredList: currentList})
    }

    render (){

        const renderMesesColumn = () => {
            var mesesColumnList = []

            if(this.props.dreConsolidado && this.props.dreConsolidado.descricaoMesesList && this.props.dreConsolidado.descricaoMesesList.length !== 0){
                this.props.dreConsolidado.descricaoMesesList.forEach(descricaoMes => {
                    // console.log("descricaoMes: ", descricaoMes)
                    mesesColumnList.push(renderColumnItem(descricaoMes))
                });
            }

            return mesesColumnList
        }

        const renderColumnItem = (descricaoMes) => {
            return (
                <Column
                    field={descricaoMes}
                    header={<div className='right-align' style={{height: ConstantsUtil.heightHeaderDREConsolidado}}>{descricaoMes}</div>}
                    // style ={ {width: ConstantsUtil.widthColumDreConsolidado} }
                    style ={ {width: GeneralServices.porcentagemColunaTabelaMesesConsolidados(this.props.qtdMeses, ConstantsUtil.tipoColunaMes)} }
                    filter={this.state.expanded} filterElement={this.renderMesTableFilter(descricaoMes)}
                    body={dreRecebimentoDTO => {
                        const mesValor = dreRecebimentoDTO.dreMensalConsolidadoMap[descricaoMes]
                        // console.log("renderColumnItem: ", dreRecebimentoDTO.dreMensalConsolidadoMap, descricaoMes, mesValor)
                        // console.log("mesValor: ", mesValor)
                        return (
                            <div className='right-align'>
                                {renderViewLancamentosLink(dreRecebimentoDTO, descricaoMes, mesValor)}
                            </div>
                        )
                    }
                    }
                />
            )
        }

        const renderViewLancamentosLink = (dreRecebimentoDTO, descricaoMes, mesValor) => {
            // const cond1 = dreRecebimentoDTO.dreMensalConsolidadoMap[descricaoMes] && dreRecebimentoDTO.dreMensalConsolidadoMap[descricaoMes].fichaBancariaUnificada.fichaBancariaList && dreRecebimentoDTO.dreMensalConsolidadoMap[descricaoMes].fichaBancariaUnificada.fichaBancariaList.length > 0
            const cond1 = dreRecebimentoDTO.dreMensalConsolidadoMap[descricaoMes] && dreRecebimentoDTO.dreMensalConsolidadoMap[descricaoMes].total && dreRecebimentoDTO.dreMensalConsolidadoMap[descricaoMes].total !== 0
            const cond2 = dreRecebimentoDTO.tipoLancamento !== ConstantsUtil.tipoTotaisFichaBancariaLabel
            if( cond1 && cond2 ){
                const classes = classNames({
                    // 'superavitValue': valorNaoFormatado >= 0,
                    'deficitValue': mesValor.total < 0,
                    'linkValue': true,
                    "small-font": true,
                    
                });
                return(
                    <a 
                        className={classes}
                        // style ={ {whiteSpace: 'nowrap'} }
                        // href='/DREConsolidado'
                        onClick={() => this.viewLancamentos(dreRecebimentoDTO, descricaoMes)}
                    >
                        { mesValor && mesValor.total ? GeneralServices.showFormattedIfNotNull(mesValor.total, true) : ConstantsUtil.valueFormattedZero}
                    </a>
                )
            }
            else{
                return(
                    mesValor && mesValor.total ? GeneralServices.showFormattedIfNotNull(mesValor.showTotal, true) : ConstantsUtil.valueFormattedZero
                )
            }
        }


        const nomeFilterElement = this.tableFilters.renderTableFilter(
            this.props.dt,
            this.state.selectedNome,
            this.filterList("selectedNome"),
            "selectedNome",
            (name, value) => {
                this.setState({[name]: value})
                this.checkPrimaryFilter(name)
            },
            'nome',
            '100%',
            "Nome"
        )        

        const renderTable = () => {
            return (
                <DataTable
                    ref={this.props.dt}
                    className="p-datatable-sm small-font"
                    value={this.filterList()}
                    // onValueChange={(currentList) => this.props.onFilterTable(currentList, this.props.dt)}
                    onValueChange={this.onFilterTable}
                    // className="p-datatable-responsive-demo"
                    rowClassName={this.rowClass}
                    rowHover
                    showGridlines
                    selection={this.state.selectedContasBancaria}
                    onSelectionChange={this.handleSelectionChange}
                    // scrollable
                    // scrollHeight="500px"
                    style={{ maxWidth: '100vw' }}
                    frozenWidth={ConstantsUtil.widthColumNomeDreConsolidado}
                    // frozenHeaderColumnGroup={frozenHeaderColumnGroup}
                    // headerColumnGroup={headerGroup}
                    loading={this.props.loading || this.state.loading}
                    dataKey="id"
                    emptyMessage={ConstantsUtil.nenhumLancamentoEncontradoMessage}
                    // paginator rows={100}
                    // paginatorTemplate={GeneralServices.tablePaginatorTemplate(100, this.props.DRE && this.props.DRE.dreRecebimentoList ? this.props.DRE.dreRecebimentoList.length : '')}
                    // currentPageReportTemplate="Mostrando de {first} ao {last} de {totalRecords} Tipos de Recebimento"
                >
    
                <Column
                    field="nome"
                    header={<div style={{height: ConstantsUtil.heightHeaderDREConsolidado}}>{"Nome"}</div>}
                    filter={this.state.expanded} filterElement={nomeFilterElement}
                    frozen
                    // style ={ {width: ConstantsUtil.widthColumNomeDreConsolidado} }
                    style ={ {width: GeneralServices.porcentagemColunaTabelaMesesConsolidados(this.props.qtdMeses, ConstantsUtil.tipoColunaNome)} }
                    body = { (rowData, rowIndex) => {
                        // console.log("rowData:", rowData)
                        // console.log("rowIndex:", rowIndex.rowIndex)
                        const uniqueId = `name_${rowIndex.rowIndex}`;
                        return(
                            <div
                                style ={ {
                                    whiteSpace: 'nowrap', /* Impede que o texto quebre em várias linhas */
                                    overflow: 'hidden', /* Esconde qualquer conteúdo que exceda o width da coluna */
                                    textOverflow: 'ellipsis', /* Adiciona reticências (...) ao final do texto cortado */
                                } }
                                title={rowData.showNome}
                            >
    
                                <span id={uniqueId}>{rowData.showNome}</span>
    
                            </div>
                        );
                    }}
                />
    
                {renderMesesColumn()}
    
                <Column field="total"
                // header="Total"
                    header={
                        <div className='right-align' style={{height: ConstantsUtil.heightHeaderDREConsolidado}}>
                            Total
                        </div>
                    }
                    // style ={ {width: ConstantsUtil.widthColumDreConsolidado} }
                    style ={ {width: GeneralServices.porcentagemColunaTabelaMesesConsolidados(this.props.qtdMeses, ConstantsUtil.tipoColunaMes)} }
                    body = {rowData =>
                        {   
                            return (
                            <div className='right-align' 
                                // style ={ {height: '100vh'} }
                            >
                                {
                                    GeneralServices.showFormattedIfNotNull(rowData.showTotal, true)
                                }
                            </div>
                            )
                        }
                    }
                />
    
                <Column
                    field="porcentagem"
                    // header="%"
                    header={<div className='right-align' style={{height: ConstantsUtil.heightHeaderDREConsolidado}}>%</div>}
                    // style ={ {width: ConstantsUtil.widthColumDrePorcentagemConsolidado} }
                    style ={ {width: GeneralServices.porcentagemColunaTabelaMesesConsolidados(this.props.qtdMeses, ConstantsUtil.tipoColunaPorcentagem)} }
                    body = {rowData => {
                        return(
                            <div className='right-align'>
                                {rowData.showPorcentagem}%
                            </div>
                        )
                    }}
                />
    
                </DataTable>
            )
        }

        const renderExportarButton = () => {
            if(this.state.expanded){
                return (
                    <Button label="Exportar"
                        icon={<i className="pi pi-file-excel" style={{fontSize: '1em', marginRight: '0.5em'}}></i>}
                        className="p-button-success small-font"
                        onClick={this.exportExcel}
                        tooltip="Exportar em excel"
                        tooltipOptions={{position: 'top'}}
                        style={{maxHeight: '15px'}}
                    />                    
                )
            }
        }

        return (
            <>

            {/* <ExpansionTableButton
                expanded={this.state.expanded}
                toggleExpansionTable={async () => {
                    await this.setState({expanded: !this.state.expanded})
                    await this.setState({selectedNome: ''})
                    this.props.dt.current.reset(); //reseta filtro
                }}
            />             */}

            <div className = "card-header header-small-height" >
            <div className='col-md-12'>
                
                <div className='row'>
                    <ExpansionTableButton
                        expanded={this.state.expanded}
                        toggleExpansionTable={async () => {
                            await this.setState({expanded: !this.state.expanded})
                            await this.setState({selectedNome: ''})
                            this.props.dt.current.reset(); //reseta filtro
                        }}
                    />                       
                    <div className='col-md-4'>
                        <h5 className='small-font-size '>Recebimento</h5>            
                    </div>
                    <div className='col-md-4'>
                        {/* <h5 className='small-font-size'>{this.getTableHeaders().periodo}</h5> */}
                    </div>
                    <div className='col-md-2'>
                    </div>
                    {renderExportarButton()}
                </div>
                
                
            </div>
            </div>

            
            {renderTable()}

            <DREFichaBancariaDialog
                visible={this.state.displayFichaBancariaDialog}
                initialDate={this.state.elementToView ? this.state.elementToView.descricaoMes.initialDate : ''}
                finalDate={this.state.elementToView ? this.state.elementToView.descricaoMes.finalDate : ''}
                element={this.state.elementToView}
                unificarEmpresas={this.props.unificarEmpresas}
                selectedEmpresas={this.props.selectedEmpresas}
                forceUpdate={this.state.forceUpdateDialog}
                doneForceUpdate={() => this.setState({forceUpdateDialog: false})}
                // fichaBancaria={this.state.fichaBancariaToView}
                search={this.props.search}
                recebimento
                saveAsExcelFile={this.props.saveAsExcelFile}
                loadingTable={false}
                hideDialog={this.hideDialog}
                push={this.props.push}
            />

            </>
        )
    }


}  

export default DRERecebimentoConsolidadoTable