import React from 'react'
import Card from '../components/card'
import FormGroup from '../components/form-group'
import { withRouter } from 'react-router-dom'
import { AuthContext } from '../main/authProvider'
import HandleErrorService from '../app/service/handleErrorService'
import { Button } from 'primereact/button'
import ConstantsUtil from '../context/constantsUtil'
import PasswordInput from '../components/passwordInput'
import GrupoDeEmpresasService from '../app/service/central/grupoDeEmpresasService'
import LoginService from '../app/service/loginService'
import LoginEmpresaDialog from '../components/loginEmpresaDialog'
import AuthService from '../app/service/authService'

const entrarLabel = "Entrar"
class Login extends React.Component{
    
    constructor(){
        super();
        this.loginService = new LoginService();
        this.grupoDeEmpresasService = new GrupoDeEmpresasService()
    }

    state = {
        email: '',
        inputEmailErrorClass: null,
        errorEmailMessage: null,
        
        password: '',
        inputPasswordErrorClass: null,
        errorPasswordMessage: null,

        preLoginGrupoResponse: null,
        visibleLoginEmpresaDialog: false,

        loginButtonMessage: entrarLabel,
        disableLoginButton: false
    }

    componentDidMount(){
        let { redirectPath, expired } = this.props.match.params;
        if(!redirectPath){
            if(AuthService.isAuth()){
                window.location = ConstantsUtil.homePathname
            }
        }
        else if(redirectPath === 'true'){
            redirectPath = ''
        }

        if(expired){
            window.location = `/login/${redirectPath}`
            this.context.endSession()
        }
    }

    handleChange = async (event) => {
        const value = event.target.value
        const name = event.target.name
        await this.setState({ [name]: value })
    }


    hanldeInputPasswordChange = (event) => {
        this.setState({password: event.target.value})
        this.setState({inputPasswordErrorClass: ''})
    }    

    resetView = () => {
        this.setState({inputEmailErrorClass: null})
        this.setState({errorEmailMessage: null})

        this.setState({inputPasswordErrorClass: null})
        this.setState({errorPasswordMessage: null})

    }

    checkData = () => {
        
        var check = true

        if (! ConstantsUtil.emailRegex.test(this.state.email) ){
            this.setState({inputEmailErrorClass: ConstantsUtil.bootstrapInputErrorClass})
            this.setState({errorEmailMessage: 'Informe um email válido'})
            check = false
        }

        if(!this.state.password){
            this.setState({inputPasswordErrorClass: ConstantsUtil.bootstrapInputErrorClass})
            this.setState({errorPasswordMessage: 'Informe a senha'})
            check = false
        }

        return check
    }

    preLogin = () => {
        this.setState({loginButtonMessage: 'Entrando...'})
        this.setState({disableLoginButton: true})
        this.loginService.preLogin({
            email: this.state.email,
            password: this.state.password
        }).then(async response => {
            await this.setState({preLoginGrupoResponse: response.data})
            this.setState({visibleLoginEmpresaDialog: true})
        })
        .catch(error => {
            this.setState({loginButtonMessage: entrarLabel})
            HandleErrorService.handleError(error)
        })
        .finally(() => {
            this.setState({disableLoginButton: false})
        })
    }

    login = (tenantName) => {
        this.resetView()
        
        if(!this.checkData()){
            return;
        }
        
        this.loginService.auth(
        {
            email: this.state.email,
            password: this.state.password,
            tenantName: tenantName
        }).then(response => {
            const data = response.data
            this.context.beginSession(data)
            let { redirectPath } = this.props.match.params;
            if(redirectPath){
                window.location = `/${redirectPath}`
            }
            else{
                window.location = ConstantsUtil.homePathname
            }
        }).catch(error => {
            if(error.response){
            var data = error.response.data
            if(data.toLowerCase().includes("email")){
            this.setState({errorEmailMessage: error.response.data})
            this.setState({inputEmailErrorClass: "is-invalid"})
            } else if(data.toLowerCase().includes("senha")){
                this.setState({errorPasswordMessage: error.response.data})
                this.setState({inputPasswordErrorClass: "is-invalid"})
            }
            }
            HandleErrorService.handleError(error)
            this.enableLoginButton()
        })
    }

    enableLoginButton = () => {
        this.setState({loginButtonMessage: entrarLabel})
        this.setState({disableLoginButton: false})
    }

    handleKeypress = e => {
        //it triggers by pressing the enter key
      if (e.key === "Enter") {
        this.preLogin();
      }
    }

    hideDialog = (shouldEnableLoginButton) => {
        this.setState({visibleLoginEmpresaDialog: false})
        if(shouldEnableLoginButton){
            this.enableLoginButton()
        }
    }

    render(){

        return(
                    <div className="bs-docs-section">
                        <div className = "col-md-3 d-flex centraliza-conteudo-div"
                        style ={{
                            margin: 'auto',

                            }} >
                        <Card title = "Login">
                            <fieldset>
                                <FormGroup label = "Email: ">
                                    <input style={{color: '#000'}} type="email"
                                    className={"form-control " + this.state.inputEmailErrorClass}
                                    value = {this.state.email}
                                    onChange = {e => this.setState({email: e.target.value})}
                                    onKeyPress={this.handleKeypress}
                                    id="exampleInputEmail1"
                                    aria-describedby="emailHelp"
                                    placeholder="Digite o Email" />
                                    <div className="invalid-feedback">{this.state.errorEmailMessage}</div>
                                </FormGroup>

                                <PasswordInput
                                    isLogin
                                    value={this.state.password}
                                    handleChange={this.hanldeInputPasswordChange}
                                    handleKeypress={this.handleKeypress}
                                    inputPasswordErrorClass={this.state.inputPasswordErrorClass}
                                    errorPasswordMessage={this.state.errorPasswordMessage}
                                />

                                <br />

                                <Button 
                                    label={this.state.loginButtonMessage}
                                    icon="pi pi-sign-in"
                                    // onClick={this.login}
                                    onClick={this.preLogin}
                                    // style={ {maxHeight: '35px'} }
                                    disabled={this.state.disableLoginButton}
                                    />
                                 <Button
                                    label="Esqueceu a senha?"
                                    className="p-button-info p-button-text"
                                    onClick = {() => {this.props.history.push('/recoverPassword')}}
                                />
                            </fieldset>
                        </Card>

                        <LoginEmpresaDialog
                            visible={this.state.visibleLoginEmpresaDialog}
                            grupos={this.state.preLoginGrupoResponse}
                            hideDialog={this.hideDialog}
                            login={this.login}
                        />
                    </div>
                 </div>
            
            
        )
    }
    
}

Login.contextType = AuthContext

export default withRouter(Login)