import React from 'react'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import GeneralServices from '../../../app/service/generalServices'
import ConstantsUtil from '../../../context/constantsUtil'
import { Button } from 'primereact/button'
import HandleErrorService from '../../../app/service/handleErrorService'
import ProvisaoService from '../../../app/service/contasAPagar/provisaoService'
import TableFilters from '../../tableFilters'
import CentralService from '../../../app/service/central/centralService'

class ProvisoesPorProdutoTable extends React.Component {

    constructor(){
        super()
        this.provisaoProdutosTableRef = React.createRef()
        this.provisaoService = new ProvisaoService()
        this.tableFilters = new TableFilters()
        this.centralService = new CentralService()
    }

    state = {
        
        provisaoProdutosResponse: {},
        provisaoProdutosList: [],
        valorTotal: null,

        loading: false,
        didUpdated: false,
    }

    componentDidMount(){
        this.setStates()
    }

    setStates = () => {
        // if( !this.state.didUpdated || this.props.forceUpdate){
        if(!this.state.didUpdated) {
            // console.log("this.props: ", this.props)
            // console.log("setStates")
            this.searchProvisaoProdutos()
            this.setState({didUpdated: true})

            
            
            // if(this.props.doneForceUpdate){
            //     this.props.doneForceUpdate()
            // }

        }
    }

    searchProvisaoProdutosIndividualOuUnificado = () => {
        if(this.props.unificar){
            return this.centralService.searchProvisaoProdutosUnificado(this.props.initialDate, this.props.finalDate, this.props.tipoProdutoList, this.props.empresasList)
        }
        return this.provisaoService.searchProvisaoProdutos(this.props.initialDate, this.props.finalDate, this.props.tipoProdutoList)
    }

    searchProvisaoProdutos = () => {
        
        this.setState({loading: true})

        this.searchProvisaoProdutosIndividualOuUnificado()
        .then(response => {
            this.setState({provisaoProdutosResponse: response.data})
            this.setState({provisaoProdutosList: response.data.provisaoProdutosList})
        })
        .catch(error => {
            HandleErrorService.handleError(error)
        })
        .finally(() => {
            this.setState({loading: false})
        })
    }

    getTableHeaders = () => {
        
        const periodo = this.props.initialDate ? this.props.initialDate + " - " + this.props.finalDate : ""
        const periodoLabel = "Período: " + periodo

        return {periodo: periodoLabel}
    }

    getValorTotal = () => {
        // console.log("getValorTotal: ", this.dt.current.value)
        if(this.state.valorTotal){
            return GeneralServices.valueBodyTemplate(this.state.valorTotal)
        }

        if(this.state.valorTotal == null && this.state.provisaoProdutosList) {
            let valorTotal = this.calculaValorTotal(this.state.provisaoProdutosList)
            return valorTotal ? GeneralServices.valueBodyTemplate(valorTotal) : ''
        }

        return GeneralServices.valueBodyTemplate(0)
    }
    
    calculaValorTotal = (currentTableList) => {
        if(!currentTableList || currentTableList.length == 0){
            return null
        }
        let valorTotal = 0
        currentTableList.forEach(item => valorTotal += item.transaction.valor)
        this.setState({valorTotal})
        return valorTotal

    }

    render() {

        const dataEntradaFilterElement = this.tableFilters.renderTableFilter(
            this.provisaoProdutosTableRef,
            this.state.selectedDataEntrada,
            this.state.provisaoProdutosList,
            "selectedDataEntrada",
            (name, value) => this.setState({[name]: value}),
            'provisao.dataLancamento',
            '300px',
            "Data de Entrada"
        )

        const nomeProdutoFilterElement = this.tableFilters.renderTableFilter(
            this.provisaoProdutosTableRef,
            this.state.selectedProduto,
            this.state.provisaoProdutosList,
            "selectedProduto",
            (name, value) => this.setState({[name]: value}),
            'transaction.productInfo.descricao',
            '300px',
            "Produto"
        )

        const tipoProdutoFilterElement = this.tableFilters.renderTableFilter(
            this.provisaoProdutosTableRef,
            this.state.selectedTipoProduto,
            this.state.provisaoProdutosList,
            "selectedTipoProduto",
            (name, value) => this.setState({[name]: value}),
            'transaction.tipoProduto.descricao',
            '300px',
            "Tipo do Produto"
        )

        const fornecedorFilterElement = this.tableFilters.renderTableFilter(
            this.provisaoProdutosTableRef,
            this.state.selectedFornecedor,
            this.state.provisaoProdutosList,
            "selectedFornecedor",
            (name, value) => this.setState({[name]: value}),
            'provisao.notaFiscal.fornecedor.nome',
            '300px',
            "Fornecedor"
        )

        const valorProdutoFilterElement = this.tableFilters.renderTableFilter(
            this.provisaoProdutosTableRef,
            this.state.selectedValorProduto,
            this.state.provisaoProdutosList,
            "selectedValorProduto",
            (name, value) => this.setState({[name]: value}),
            'transaction.valor',
            '300px',
            "Valor do Produto"
        )

        const notaFiscalFilterElement = this.tableFilters.renderTableFilter(
            this.provisaoProdutosTableRef,
            this.state.selectedNotaFiscal,
            this.state.provisaoProdutosList,
            "selectedNotaFiscal",
            (name, value) => this.setState({[name]: value}),
            'provisao.notaFiscal.numero',
            '300px',
            "N° NF"
        )

        const empresaFilterElement = this.tableFilters.renderTableFilter(
            this.provisaoProdutosTableRef,
            this.state.selectedEmpresa,
            this.state.provisaoProdutosList,
            "selectedEmpresa",
            (name, value) => this.setState({[name]: value}),
            'empresa.nome',
            '100%',
            "Empresa"
        )

        const historicoFilterElement = this.tableFilters.renderTableFilter(
            this.provisaoProdutosTableRef,
            this.state.selectedHistorico,
            this.state.provisaoProdutosList,
            "selectedHistorico",
            (name, value) => this.setState({[name]: value}),
            'provisao.historico',
            '100%',
            "Histórico"
        )

        const renderHeader = () => {
            return (
                <div className = "card-header">
                <div className='col-md-12'>
                    <div className='row'>
                        <div className='col-md-4'>
                            <h5 className='medium-font-size'>{this.getTableHeaders().periodo}</h5>
                        </div>
                        <div className='col-md-4'>
                        </div>
                        <div className='col-md-4 d-flex justify-content-end'>
                            <Button
                                label={'Valor Total: ' + this.getValorTotal()}
                                className="p-button-success"
                            />
                        </div>
                    </div>
                    
                </div>
                </div>                
            )
        }

        const renderEmpresaColumn = () => {
            if(this.props.unificar)
            {
                return (
                    <Column
                        header="Empresa"
                        field="empresa.nome"
                        // body = {rowData => GeneralServices.showFormattedIfNotNull(rowData.valorTotalProvisao, true)}
                        sortable style ={ {width: '30px'} }
                        filter filterElement={empresaFilterElement}
                    />
                )
            }
        }        

        const renderTable = () => {
            return (
                <DataTable
                    ref={this.provisaoProdutosTableRef}
                    value={this.state.provisaoProdutosList}
                    onValueChange={this.calculaValorTotal}
                    className="p-datatable-sm small-font"
                    rowHover
                    // rowClassName={this.rowClass}
                    showGridlines
                    scrollable
                    scrollHeight={ this.props.insideDialog ? '280px' : "400px"}
                    emptyMessage={ConstantsUtil.nenhumLancamentoEncontradoMessage}
                    loading={this.state.loading}
                    dataKey="transaction.id"
                    paginator rows={ConstantsUtil.initialTableQuantityOption}
                    paginatorTemplate={GeneralServices.tablePaginatorTemplate(ConstantsUtil.initialTableQuantityOption, this.state.provisaoProdutosList.length)}
                    currentPageReportTemplate="Mostrando de {first} ao {last} de {totalRecords} lançamentos"
                >

                    <Column
                        header="Data de Entrada"
                        field="provisao.notaFiscal.dataHoraEntrada"
                        sortable style ={ {width: '35px'} }
                        filter filterElement={dataEntradaFilterElement}
                    />

                    <Column
                        header="Produto"
                        field="transaction.productInfo.descricao"
                        body = { (rowData, rowIndex) => GeneralServices.renderBodyColumnInOneLine(rowData.transaction.productInfo.descricao, rowIndex)}
                        sortable style ={ {width: '70px'} }
                        filter filterElement={nomeProdutoFilterElement}
                    />

                    <Column
                        header="Tipo do Produto" 
                        field="transaction.tipoProduto.descricao"
                        body = { (rowData, rowIndex) => GeneralServices.renderBodyColumnInOneLine(rowData.transaction.tipoProduto.descricao, rowIndex)}
                        sortable style ={ {width: '40px'} }
                        filter filterElement={tipoProdutoFilterElement}
                    />

                    <Column
                        header="Fornecedor" 
                        field="provisao.notaFiscal.nomeFornecedorOuCliente" 
                        sortable style ={ {width: '80px'} }
                        filter filterElement={fornecedorFilterElement}
                    />

                    <Column
                        header="Valor do Produto" 
                        field="transaction.valor" 
                        sortable style ={ {width: '40px'} }
                        filter filterElement={valorProdutoFilterElement}
                        body = {rowData => GeneralServices.showFormattedIfNotNull(rowData.transaction.valor, true)}
                    />

                    <Column
                        header="Nota Fiscal"
                        field="provisao.notaFiscal.numero" 
                        sortable style ={ {width: '45px'} }
                        filter filterElement={notaFiscalFilterElement}
                        body = {rowData => `${rowData.provisao.notaFiscal.numero ? "Nota Fiscal N° " + rowData.provisao.notaFiscal.numero : "S/N"}`}
                    />

                    {renderEmpresaColumn()}

                    <Column
                        header="Histórico"
                        style ={ {width: '60px'} }
                        field="provisao.historico"
                        body = { (rowData, rowIndex) => GeneralServices.renderBodyColumnInOneLine(rowData.provisao.historico, rowIndex)}
                        sortable
                        filter filterElement={historicoFilterElement}
                    />

                </DataTable>
            )
        }

        return (
            <>
                {renderHeader()}
                {renderTable()}
            </>
        )
    }

}

export default ProvisoesPorProdutoTable