import React from 'react'
import ConstantsUtil from '../../../../context/constantsUtil'
import GeneralServices from '../../../../app/service/generalServices'
import { Button } from 'primereact/button'
import { Dialog } from 'primereact/dialog'
import DialogFooter from '../../../dialogFooter'
import RelatorioFechamentoCaixaTable from '../../fechamentoCaixa/relatorioFechamentoCaixaTable'
import FichaDeRecebimentoConsolidadaTable from '../../../fichaDeRecebimento/fichaDeRecebimentoConsolidadaTable'
import ProvisaoTable from '../../../contasAPagar/provisoes/provisaoTable'
import { AuthContext } from '../../../../main/authProvider'
import TipoDePagamentoService from '../../../../app/service/contasAPagar/tipoDePagamentoService'
import HandleErrorService from '../../../../app/service/handleErrorService'
import TableFilters from '../../../tableFilters'
import DREFichaBancariaDialog from '../../DRE/DREFichaBancariaDialog'
import TiposDeRecebimentoService from '../../../../app/service/tiposDeRecebimentoService'
import ProvisoesPorProdutoTable from '../../../contasAPagar/provisoes/provisoesPorProdutoTable'
import SelectMenu from '../../../selectMenu'

class DRECompetenciaMensalDetalhamentoDialog extends React.Component {

    constructor(){
        super();
        this.generalServices = new GeneralServices();
        this.provisaoTableRef = React.createRef();
        this.tipoDePagamentoService = new TipoDePagamentoService();
        this.tiposDeRecebimentoService = new TiposDeRecebimentoService()
        this.tableFilters = new TableFilters();
    }

    state = {

        
        didUpdate: false,
        
        displayFechamentoCaixaTable: false,

        descricaoDetalhamento: '',

        displayFichaDeRecebimentoConsolidadaTable: false,
        fichaDeRecebimento: {},

        displayProvisaoTable: false,
        searchProvisaoResult: '',

        loading: false,

        displayDialogRendApAuto: false,
        forceUpdateDialog: false,
        tipoDeRecebimentoRendApAutoToView: null,

        displayProvisaoPorProdutosTable: false,
        tiposProdutosList: [],

        empresasSelectList: [],

    }

    componentDidUpdate(){
        if(this.props.visible && !this.state.didUpdate){
            this.searchDetalhamento()
            this.setState({didUpdate: true})
            this.buildEmpresasSelectList()
            
        }
    }

    buildEmpresasSelectList = async () => {
        if(this.props.unificar){
            const empresasSelectList = this.props.selectedEmpresas.map(empresa => {return {label: empresa.nome, value: empresa.id}})
            await this.setState({empresasSelectList})
            this.generalServices.getLoggedSystemData( async (response) => {
                // console.log("getLoggedSystemData: ", response)
                // console.log("this.state.empresasSelectList:", this.state.empresasSelectList)
                let empresaLogada = this.state.empresasSelectList.find(selectItem => selectItem.label === response.data.nomeEmpresa)
                // console.log("empresaLogada: ", empresaLogada)
                await this.setState({selectedEmpresa: empresaLogada.value})
                // console.log("this.state.selectedEmpresa: ", this.state.selectedEmpresa)
            })

        }

    }


    searchDetalhamento = () => {
        // console.log("this.props.grupo: ", this.props.grupo)
        // console.log("this.props.item: ", this.props.item)

        switch(this.props.grupo.tipoDescricao){
           
            case ConstantsUtil.DRECompetenciaGrupoReceitaBruta:
                if(!this.props.item) return
                this.setState({displayFechamentoCaixaTable: true})
                this.setState({descricaoDetalhamento: "Fechamento de Caixa"})
                break;
            
            case ConstantsUtil.DRECompetenciaGrupoDeducoesReceita:
                this.openFichaDeRecebimentoConsolidada()
                this.setState({descricaoDetalhamento: "Comissões da Ficha de Recebimento"})
                break;

            case ConstantsUtil.DRECompetenciaGrupoImpostos:
                this.openConferenciaProvisaoPagamento(false)
                this.setState({descricaoDetalhamento: ConstantsUtil.provisoesDePagamentoHeaderLabel})
                break;

            case ConstantsUtil.DRECompetenciaGrupoCMV:
                this.setState({descricaoDetalhamento: ConstantsUtil.provisoesDePagamentoHeaderLabel})
                this.openProvisoesPorProdutos([ConstantsUtil.tipoProdutoVendaLabel, ConstantsUtil.tipoProdutoInsumoVendaLabel, ConstantsUtil.tipoProdutoBonificacaoLabel])
                break;                

            case ConstantsUtil.DRECompetenciaGrupoReceitaFinanceira:
                if(this.props.item && this.props.item.nome === ConstantsUtil.DRECompetenciaItemReceitaAplicacoesFinanceiras){
                    this.mostraDetalhamentoRendApAuto()
                }
                else if(this.props.item && this.props.item.nome === ConstantsUtil.DRECompetenciaItemReceitaBonificacao){
                    this.setState({descricaoDetalhamento: ConstantsUtil.provisoesDePagamentoHeaderLabel})
                    this.openProvisoesPorProdutos([ConstantsUtil.tipoProdutoBonificacaoLabel])
                }
                break;

            case ConstantsUtil.DRECompetenciaGrupoDespesasPessoal:
                this.openConferenciaProvisaoPagamento(true)
                this.setState({descricaoDetalhamento: ConstantsUtil.provisoesDePagamentoHeaderLabel})
                break;

            case ConstantsUtil.DRECompetenciaGrupoDespesasFuncionamento:
                this.openConferenciaProvisaoPagamento(true)
                this.setState({descricaoDetalhamento: ConstantsUtil.provisoesDePagamentoHeaderLabel})
                break;


            case ConstantsUtil.DRECompetenciaGrupoOutrasDespesas:
                if(this.props.item.isProduto){
                    this.setState({descricaoDetalhamento: ConstantsUtil.provisoesDePagamentoHeaderLabel})
                    this.openProvisoesPorProdutos([this.props.item.tipoProduto.descricao])
                }
                else{
                    this.openConferenciaProvisaoPagamento(true)
                    this.setState({descricaoDetalhamento: ConstantsUtil.provisoesDePagamentoHeaderLabel})
                }
                break;
                
        }
    }

    mostraDetalhamentoRendApAuto = () => {
        this.tiposDeRecebimentoService.searchRendApAutoAsDreDto()
        .then(async response => {
            await this.setState({tipoDeRecebimentoRendApAutoToView: response.data})
            this.setState({forceUpdateDialog: true})
            this.setState({displayDialogRendApAuto: true})
        })
        .catch(error => {
            HandleErrorService.handleError(error)
        })
    }
        
    openFichaDeRecebimentoConsolidada = async () => {
        await this.setState({fichaDeRecebimento:
            {
                initialDate: this.props.dataInicial,
                finalDate: this.props.dataFinal,
                dateFilterType: ConstantsUtil.dataLancamentoValue,
                doAutoSearch: true,
                
            }
        })
        this.setState({displayFichaDeRecebimentoConsolidadaTable: true})
    }

    openConferenciaProvisaoPagamento = (fromGrupoDePagamento) => {
        
        this.setState({displayProvisaoPorProdutosTable: false})

        let obj = {
            filterTypeDate: ConstantsUtil.dataLancamentoValue,
            initialDate: this.props.dataInicial,
            finalDate: this.props.dataFinal,
        }

        if(fromGrupoDePagamento){
            obj.filterType = ConstantsUtil.filtroProvisaoGrupoDePagamentoNome
            obj.nomeGrupoDePagamento = this.props.item.nome
        }
        else{
            obj.filterType = ConstantsUtil.filtroProvisaoTipoDePagamentoNome
            obj.nomeTipoDePagamento = this.props.item.nome

        }

        //Define a função onSuccess
        let onSuccess = async (responseData) => {
            
            await this.setState({searchProvisaoResult: responseData.dados})

        }

        //Define a função onFinally
        let onFinally = () => {
            this.setState({loading: false})
        }

        this.setState({loading: true})

        //Realiza a busca
        this.generalServices.searchProvisoes(
            obj,
            onSuccess,
            onFinally,
            this.props.unificar,
            this.props.selectedEmpresas
        )

        this.setState({displayProvisaoTable: true})

    }

    openProvisoesPorProdutos = async (tiposProdutosList) => {
    
        this.setState({displayProvisaoTable: false})
        tiposProdutosList = tiposProdutosList.map(tipoProduto => {return {descricao: tipoProduto} })
        await this.setState({tiposProdutosList: tiposProdutosList})
    
        this.setState({displayProvisaoPorProdutosTable: true})
    }

    hideDialog = () => {
        this.resetState()
        this.props.hideDialog()
        this.setState({didUpdate: false})
        this.setState({descricaoDetalhamento: ''})
    }

    resetState = () => {
        this.setState({tipoDeRecebimentoRendApAutoToView: null})
        this.setState({displayFechamentoCaixaTable: false})
        this.setState({displayDialogRendApAuto: false})
    }

    handleSelectEmpresaChange = async (event) => {
        const name = event.target.name
        const value = event.target.value
        await this.setState({ [name]: value })
        this.researchDetalhamento()
        // console.log("this.state.selectedEmpresa: ", this.state.selectedEmpresa)
    }

    researchDetalhamento = async () => {
        if(this.state.displayFechamentoCaixaTable){
            this.setState({displayFechamentoCaixaTable: false})
            this.setState({displayFechamentoCaixaTable: true})
        }
        
        else if(this.state.displayFichaDeRecebimentoConsolidadaTable){
            await this.setState({displayFichaDeRecebimentoConsolidadaTable: false})
            let fichaDeRecebimento = this.state.fichaDeRecebimento
            fichaDeRecebimento.doAutoSearch=true
            fichaDeRecebimento.dateFilterType = fichaDeRecebimento.dateFilterTypeEnum
            fichaDeRecebimento.initialDate = fichaDeRecebimento.initialDate.replaceAll("/", "-")
            fichaDeRecebimento.finalDate = fichaDeRecebimento.finalDate.replaceAll("/", "-")
            await this.setState({fichaDeRecebimento})
            this.setState({displayFichaDeRecebimentoConsolidadaTable: true})
        }

    }

    researchAllDRE = () => {
        if(this.state.displayFechamentoCaixaTable){
            this.setState({displayFechamentoCaixaTable: false})
        }
        else if(this.state.displayFichaDeRecebimentoConsolidadaTable){
            this.setState({displayFichaDeRecebimentoConsolidadaTable: false})
        }
        else if(this.state.displayProvisaoTable){
            this.setState({displayProvisaoTable: false})
        }
        else if(this.state.displayDialogRendApAuto){
            this.setState({displayDialogRendApAuto: false})
        }
        else if(this.state.displayProvisaoPorProdutosTable){
            this.setState({displayProvisaoPorProdutosTable: false})
        }
        this.props.searchAllDRE()
    }

    render(){

        const footer = (
            <DialogFooter hideDialog = {this.hideDialog} disabled/>
        )

        const renderDetalhamento = () => {
            return (
                <>
                    {renderFechamentoDeCaixaTable()}
                    {renderFichaDeRecebimentoConsolidadaTable()}
                    {renderProvisaoTable()}
                    {renderProvisaoPorProdutosTable()}
                </>
            )
        }

        const renderFechamentoDeCaixaTable = () => {
            if(this.state.displayFechamentoCaixaTable){
                return (
                    <RelatorioFechamentoCaixaTable
                        dataInicial = {this.props.dataInicial}
                        dataFinal = {this.props.dataFinal}
                        especificar = {false}
                        scrollHeight="50vh"
                        hidePaginator
                        specificEmpresaId={this.state.selectedEmpresa}
                    />
                )
            }
        }

        const renderFichaDeRecebimentoConsolidadaTable = () => {
            if(this.state.displayFichaDeRecebimentoConsolidadaTable) {
                return (
                    <>
                    <FichaDeRecebimentoConsolidadaTable
                        fichaDeRecebimento={this.state.fichaDeRecebimento}
                        updateProps={(fichaDeRecebimento) => this.setState({fichaDeRecebimento})}
                        insideDialog
                        specificEmpresaId={this.state.selectedEmpresa}
                        sortType={ConstantsUtil.fichaDeRecebimentoSortTypeComissao}
                    />
                    </>
                )
            }
        }

        const renderProvisaoTable = () => {
            if(this.state.displayProvisaoTable) {
                return (
                    <ProvisaoTable
                        ref={this.provisaoTableRef}
                        searchProvisaoResult={this.state.searchProvisaoResult}
                        perfil = {this.context.perfil}
                        loading={this.state.loading}
                        search={this.researchAllDRE}
                        insideDialog
                        initialDate={this.props.dataInicial}
                        finalDate={this.props.dataFinal}
                    />
                )
            }
        }

        const renderProvisaoPorProdutosTable = () => {
            if(this.state.displayProvisaoPorProdutosTable) {
                return(
                    <ProvisoesPorProdutoTable
                        initialDate={this.props.dataInicial}
                        finalDate={this.props.dataFinal}
                        tipoProdutoList={this.state.tiposProdutosList}
                        unificar={this.props.unificar}
                        empresasList={this.props.selectedEmpresas}
                        insideDialog
                    />
                )
            }
        }

        const renderTopOptions = () => {
            return (
                <div 
                    className='row'
                    style={{marginTop: '0.1rem'}}
                >
                    
                    <div className='col-md-2'>
                        {renderValorItem()}
                    </div>

                    <div className='col-md-6'>
                    </div>

                    <div className='col-md-4'>
                        {renderSelectEmpresa()}
                    </div>

                </div>
            )
        }

        const renderValorItem = () => {
            return (
                <>
                    <Button
                        label={`Valor: ${GeneralServices.showFormattedIfNotNull(this.props.item ? this.props.item.valor : this.props.grupo.total, true)}`}
                        className={`small-font `}
                        style={{maxHeight: '10px', width: '12rem',}}
                    />                
                </>
            )
        }

        const renderSelectEmpresa = () => {
            if(this.props.unificar && (this.state.displayFechamentoCaixaTable || this.state.displayFichaDeRecebimentoConsolidadaTable) ){
                return (
                    <>
                    <SelectMenu
                            className={"form-control "}
                            name="selectedEmpresa"
                            list={this.state.empresasSelectList}
                            value={this.state.selectedEmpresa}
                            onChange={this.handleSelectEmpresaChange}
                    />
                    </>
                )
            }
        }

        const renderCommomDetalhamentoDialog = () => {
            return (
                <Dialog
                    visible={this.props.visible}
                    style={{ width: '98vw', height: '95vh' }}
                    header={`${this.props.item ? this.props.item.nome : this.props.grupo.tipoDescricao} (${this.props.dataInicial} - ${this.props.dataFinal}) (${this.state.descricaoDetalhamento})`}
                    modal
                    className="p-fluid"
                    footer={footer}
                    onHide={this.hideDialog}
                >
                    {renderTopOptions()}

                    {renderDetalhamento()}

                </Dialog>                
            )
        }

        const renderDialogRendApAuto = () => {
            return(
                <>
                    <DREFichaBancariaDialog
                        visible={this.props.visible}
                        headerPrefix="Fluxo de Caixa Realizado"
                        initialDate={this.props.dataInicial}
                        finalDate={this.props.dataFinal}
                        element={this.state.tipoDeRecebimentoRendApAutoToView}
                        unificarEmpresas={this.props.unificar}
                        selectedEmpresas={this.props.selectedEmpresas}
                        forceUpdate={this.state.forceUpdateDialog}
                        doneForceUpdate={() => this.setState({forceUpdateDialog: false})}
                        // fichaBancaria={this.state.fichaBancariaToView}
                        // search={this.props.search}
                        recebimento
                        fromDRECompetencia
                        saveAsExcelFile={GeneralServices.saveAsExcelFile}
                        loadingTable={false}
                        hideDialog={this.hideDialog}
                    />
                </>
            )
        }

        const renderDialog = () => {
            if(this.state.displayDialogRendApAuto) {
                return renderDialogRendApAuto()
            }
            else {
                return renderCommomDetalhamentoDialog()
            }
        }

        return (
            <>
                {renderDialog()}
            </>
        )
    }

}

DRECompetenciaMensalDetalhamentoDialog.contextType = AuthContext

export default DRECompetenciaMensalDetalhamentoDialog