import React from 'react'
import { Toast } from 'primereact/toast'
import { Button } from 'primereact/button'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'

import UserService from '../app/service/userService'
import EmailService from '../app/service/emailService'
import HandleErrorService from '../app/service/handleErrorService'
import * as popUp from './toastr'
import ConstantsUtil from '../context/constantsUtil'
import CustomHighlighter from './customHighlighter'
import GeneralServices from '../app/service/generalServices'
import UserDialog from './userDialog'
import TableFilters from './tableFilters'

class UsersTable extends React.Component {

    constructor(){
        super()
        this.dt = React.createRef()
        this.userService = new UserService();
        this.emailService = new EmailService();
        this.tableFilters = new TableFilters();
    }

    state = {
        list: [],

        userToEdit: '',

        displayUserDialog: false,
        forceUpdateUserDialog: false,
    }

    componentDidMount(){
        this.searchUsers()
    }

    searchUsers = () => {
        this.setState({loading: true})
        this.userService.getAllUsers()
        .then(response => {
            // console.log("response data", response.data)
            let userList = response.data
            this.trataDados(userList)
            this.setState({list: userList})
            this.setState({loading: false})
        })
        .catch(error => {
            HandleErrorService.handleError(error)
            this.setState({loading: false})
        })
    }

    trataDados = userList => {
        userList.forEach(user => {
            user.recebeNotificacaoString = user.recebeNotificacao ? 'Sim' : 'Não'
            user.statusString = user.status ? 'ATIVO' : 'INATIVO'
            user.senhaDefinidaString = user.definedPassword ? 'Sim' : 'Não'
            user.perfilTratado = this.trataLabelPerfil(user.perfil)
        })
    }

    trataLabelPerfil = perfil => {
        switch(perfil){

            case ConstantsUtil.perfilAdministradorLabel:
                return ConstantsUtil.perfilAdministradorLabelTratada
            

            case ConstantsUtil.perfilOperadorLabel:
                return ConstantsUtil.perfilOperadorLabelTratada
            

            case ConstantsUtil.perfilOperadorCegoLabel:
                return ConstantsUtil.perfilOperadorCegoLabelTratada
            

            case ConstantsUtil.perfilDiretorLabel:
                return ConstantsUtil.perfilDiretorLabelTratada
            

        }
    }

    editUser = (user) => {
        this.setState({userToEdit: JSON.parse(JSON.stringify(user))})

        this.setState({displayUserDialog: true})
        this.setState({forceUpdateUserDialog: true})
    }

    hideDialog = () => {
        this.setState({displayUserDialog: false})
    }

    recoverPassword = (user) => {
        this.setState({loading: true})
        const obj = {
            email: user.email,
            url: window.location.href.replace(this.props.pathname, ConstantsUtil.recoverPasswordPath)
        }
        this.emailService.recoverPassword(obj)
        .then(response => {
            this.setState({loading: false})
            popUp.successPopUp(ConstantsUtil.mensagemEnvioEmail)
        }).catch(error => {
            this.setState({loading: false})
            HandleErrorService.handleError(error)
        })
    }

    changeUserStatus = (user, activate) => {
        this.setState({loading: true})
        this.userService.changeUserStatus(user)
        .then(response => {
            this.setState({loading: false})
            this.searchUsers()
            const action = activate ? 'ativado' : 'inativado'
            popUp.successPopUp(`Usuário ${action} com sucesso!`)
        }).catch(error => {
            this.setState({loading: false})
            HandleErrorService.handleError(error)
        })
    }

    deleteUser = (user) => {
        this.setState({loading: true})
        this.userService.delete(user)
        .then(response => {
            this.setState({loading: false})
            this.searchUsers()
            popUp.successPopUp(`Usuário deletado com sucesso!`)
        }).catch(error => {
            this.setState({loading: false})
            HandleErrorService.handleError(error)
        })
    }

    filter = () => {
        var filteredList = []
        this.state.list.forEach(element => {
            if(
                GeneralServices.includeString(element.name, this.props.searchText)
                ||
                GeneralServices.includeString(element.email, this.props.searchText)
            )
            filteredList.push(element)
        })
        return filteredList
    }

    render() {

        const renderActivateUserButton = (user) => {
            if(user.status){
                return (
                    <>
                    <Button
                        tooltip={"Inativar Usuário." + "\n" + "O usuário não terá mais permissão para acessar o sistema.\n No entanto, poderá ser ativado futuramente"}
                        // tooltip="Inativar Usuário"
                        tooltipOptions={{position: 'top'}}
                        icon="pi pi-times"
                        className={ "p-button-rounded p-mr-2 p-button-danger" }
                        style ={{marginLeft: '10px', maxHeight: '30px', maxWidth: '30px'}}
                        onClick={() => this.changeUserStatus(user, false)}
                    />
                    </>
                )
            }
            return (
                <>
                    <Button
                        tooltip={"Ativar Usuário.\n O usuário terá permissão para acessar o sistema.\n Novos usuários cadastrados serão automaticamente ativados quando confirmarem seu cadastro, definindo a senha de acesso."}
                        // tooltip="Inativar Usuário"
                        tooltipOptions={{position: 'top'}}
                        icon="pi pi-check"
                        className={ "p-button-rounded p-mr-2 p-button-success" }
                        style ={{marginLeft: '10px', maxHeight: '30px', maxWidth: '30px'}}
                        onClick={() => this.changeUserStatus(user, true)}
                    />                
                </>
            )
        }

        const actionBody = (user) => {
            if(user.id === ConstantsUtil.systemUserId){
                return
            }
            return (
                <React.Fragment>
                    <Button 
                        tooltip="Editar dados do usuário."
                        tooltipOptions={{position: 'top'}}
                        icon="pi pi-pencil"
                        className={ "p-button-rounded p-mr-2 " }
                        onClick={() => this.editUser(user)}
                        style={ {maxHeight: '30px', maxWidth: '30px'} }
                    />

                    <Button 
                        tooltip={"Recuperar Senha." + "\n" + "Um email será enviado para que o usuário redefina a senha."}
                        tooltipOptions={{position: 'top'}}
                        icon="pi pi-refresh"
                        className={ "p-button-rounded p-mr-2 " }
                        style={ {marginLeft: '10px', maxHeight: '30px', maxWidth: '30px'} }
                        onClick={() => this.recoverPassword(user)}
                    />

                    {renderActivateUserButton(user)}

                    <Button
                        tooltip={"Deletar Usuário." + "\n" + "O usuário será excluído da base de dados."}
                        // tooltip="Inativar Usuário"
                        tooltipOptions={{position: 'top'}}
                        icon="pi pi-trash"
                        className={ "p-button-rounded p-mr-2 p-button-danger" }
                        style ={{marginLeft: '10px', maxHeight: '30px', maxWidth: '30px'}}
                        onClick={() => this.deleteUser(user)}
                    />
 
                </React.Fragment>
            );
        }

        const nomeFilterElement = this.tableFilters.renderTableFilter(
            this.dt,
            this.state.selectedNome,
            this.filter(),
            "selectedNome",
            (name, value) => this.setState({[name]: value}),
            'name',
            '100%',
            "Nome"
        ) 

        const emailFilterElement = this.tableFilters.renderTableFilter(
            this.dt,
            this.state.selectedEmail,
            this.filter(),
            "selectedEmail",
            (name, value) => this.setState({[name]: value}),
            'email',
            '100%',
            "Email"
        ) 

        const perfilFilterElement = this.tableFilters.renderTableFilter(
            this.dt,
            this.state.selectedPerfil,
            this.filter(),
            "selectedPerfil",
            (name, value) => this.setState({[name]: value}),
            'perfilTratado',
            '100%',
            "Perfil"
        ) 

        const notificacaoFilterElement = this.tableFilters.renderTableFilter(
            this.dt,
            this.state.selectedNotificacao,
            this.filter(),
            "selectedNotificacao",
            (name, value) => this.setState({[name]: value}),
            'recebeNotificacaoString',
            '100%',
            "Notificação"
        ) 

        const statusFilterElement = this.tableFilters.renderTableFilter(
            this.dt,
            this.state.selectedStatus,
            this.filter(),
            "selectedStatus",
            (name, value) => this.setState({[name]: value}),
            'statusString',
            '100%',
            "Status"
        )

        const senhaDefinidaFilterElement = this.tableFilters.renderTableFilter(
            this.dt,
            this.state.selectedSenhaDefinida,
            this.filter(),
            "selectedSenhaDefinida",
            (name, value) => this.setState({[name]: value}),
            'senhaDefinidaString',
            '100%',
            "Senha"
        )

        return (
            <div >
                
            {/* <Toast ref={this.toast} /> */}

            <div className="card">
                {/* <Toolbar className="p-mb-4" left={leftToolbarTemplate} 
                // right={rightToolbarTemplate}
                /> */}

                <DataTable ref={this.dt} value={this.filter()}
                    className="p-datatable-sm small-font"
                    rowHover
                    emptyMessage="Não há usuários cadastrados."
                    // selection={this.state.selectedContasBancaria}
                    // onSelectionChange={this.handleSelectionChange}
                    // scrollable
                    // scrollHeight="500px"
                    loading={this.state.loading || this.props.loading}
                    dataKey="id"
                    paginator rows={ConstantsUtil.initialTableQuantityOption}
                    paginatorTemplate={GeneralServices.tablePaginatorTemplate(ConstantsUtil.initialTableQuantityOption, this.filter() ? this.filter().length : '')}
                    currentPageReportTemplate="Mostrando de {first} ao {last} de {totalRecords} usuários"                    
                >

                    {/* <Column selectionMode="multiple" headerStyle={{ width: '3rem' }}></Column> */}

                    <Column
                        header="Nome"
                        field="name"
                        sortable
                        style ={ {width: '200px'} }
                        filter filterElement={nomeFilterElement}
                        // body={rowData => 
                        //     <CustomHighlighter
                        //         searchWords={[this.props.searchText]}
                        //         textToHighlight={rowData.name}
                        //     />
                        // }
                    />

                    <Column
                        header="Email"
                        field="email"
                        sortable
                        style ={ {width: '200px'} }
                        filter filterElement={emailFilterElement}
                        // body={rowData => 
                        //     <CustomHighlighter
                        //         searchWords={[this.props.searchText]}
                        //         textToHighlight={rowData.email}
                        //     />
                        // }                     
                    />

                    <Column
                        header="Perfil"
                        field="perfilTratado"
                        sortable
                        style ={ {width: '200px'} }
                        filter filterElement={perfilFilterElement}
                    />

                    <Column
                        header="Notificações"
                        field="recebeNotificacaoString"
                        sortable
                        style ={ {width: '200px'} }
                        filter filterElement={notificacaoFilterElement}
                        body={rowData => {
                            return GeneralServices.renderStyledColorTableColumn(rowData.recebeNotificacaoString, rowData.recebeNotificacao)
                        }}
                    />

                    <Column
                        header="Status"
                        field="statusString"
                        sortable
                        style ={ {width: '200px'} }
                        filter filterElement={statusFilterElement}
                        body={rowData => {
                            return GeneralServices.renderStyledColorTableColumn(rowData.statusString , rowData.status)
                        }}
                    />

                    <Column
                        header="Senha Definida"
                        field="senhaDefinidaString"
                        sortable
                        style ={ {width: '200px'} }
                        filter filterElement={senhaDefinidaFilterElement}
                        body={rowData => {
                            return GeneralServices.renderStyledColorTableColumn(rowData.senhaDefinidaString , rowData.definedPassword)
                        }}                        
                    />

                    <Column header="Ações" body={actionBody} style ={ {width: '200px'} }/>

                </DataTable>
            </div>
            
            <UserDialog  
                visible={this.state.displayUserDialog}
                forceUpdate={this.state.forceUpdateUserDialog}
                doneForceUpdate={() => this.setState({forceUpdateUserDialog: false})}
                hideDialog={this.hideDialog}
                userToEdit={this.state.userToEdit}
                search={this.searchUsers}
                pathname={this.props.pathname}
            />

            </div>
        )
    }

}

export default UsersTable