import WorkerApiService from "../../workerApiServices";


class PDVLancamentoService extends WorkerApiService {

    constructor() {
        super('/api/pdvLancamento')
    }

    getPDV(dataLancamento, pdvCaixaId, turno){
        let params = `?`
        params = `${params}&dataLancamento=${dataLancamento}`
        params = `${params}&pdvCaixaId=${pdvCaixaId}`
        params = `${params}&turno=${turno}`
        return this.get(`/search${params}`)
    }

    save(object){
        return this.post("/save", object)
    }

    calcularPDV(dataInicial, dataFinal){
        let params = `?`
        params = `${params}&dataInicial=${dataInicial}`
        params = `${params}&dataFinal=${dataFinal}`
        return this.get(`/calcularPDV${params}`)
    }

}

export default PDVLancamentoService