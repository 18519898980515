import React from 'react'
import { withRouter } from 'react-router-dom'

import { TextField } from '@mui/material'
import { Button } from 'primereact/button'

import { AuthContext } from '../main/authProvider'

import PDVCaixaService from '../app/service/contasAReceber/pdvCaixaService'
import Card from '../components/card'
import * as popUp from '../components/toastr'
import HandleErrorService from '../app/service/handleErrorService'
import PDVCaixaTable from '../components/pdvCaixa/pdvCaixaTable'
import UsersTable from '../components/usersTable'
import UserDialog from '../components/userDialog'


class CadastroUsuarios extends React.Component{

    constructor(){
        super();
        this.userTableRef = React.createRef()
    }    

    state = {

        searchText: '',

        displayUserDialog: false,

        disableSingUpButton: false,


    }

    handleChange =  (event) => {
        const value = event.target.value
        const name = event.target.name
        this.setState({[name]: value})
    }

    handleMuiKeyUp = (event) => {
        if(event.code === "Enter" || event.code === "NumpadEnter"){
            this.callSave()
        }
    }

    hideDialog = () => {
        this.setState({displayUserDialog: false})
    }

    showDialog = () => {
        this.setState({displayUserDialog: true})
    }

    beginSignUp = () => {
        this.setState({disableSingUpButton: true})
        this.setState({displayUserDialog: false})
        this.setState({loading: true})
    }
    
    endSignUp = () => {
        this.setState({disableSingUpButton: false})
        this.setState({loading: false})
       this.userTableRef.current.searchUsers()
    }

    render(){

        const renderCadastroUsuarioButton = () => {
            return (
                <Button 
                    label={this.state.disableSingUpButton ? "Cadastrando..." : "Cadastrar Novo Usuário"}
                    icon="pi pi-plus"
                    onClick = {() => this.setState({displayUserDialog: true})}
                    disabled = {this.state.disableSingUpButton}
                />
            )
        }

        return(
            <div className="bs-docs-section" >
            <Card
                title = "Usuários Cadastrados"
                rightComponent={renderCadastroUsuarioButton()}
            >
                
            <div className = "col-md-12">

                <br />

                <div className="row">
                
                    <UsersTable
                        ref={this.userTableRef}
                        searchText={this.state.searchText}
                        pathname= {this.props.location.pathname}
                        loading={this.state.loading}
                    />

                </div>


            </div>

            </Card>
            <div className="d-flex "/>

            <UserDialog
                hideDialog={this.hideDialog}
                showDialog={this.showDialog}
                visible={this.state.displayUserDialog}
                beginSignUp={this.beginSignUp}
                endSignUp={this.endSignUp}
                state={{}}
                isCadastro
                search={this.searchUsers}
                pathname= {this.props.location.pathname}
                push={this.props.history.push}
            />

            </div>
        )
    }

}

CadastroUsuarios.contextType = AuthContext
export default withRouter( CadastroUsuarios )