import { MultiSelect } from 'primereact/multiselect'
import React from 'react'
import GrupoDeEmpresasService from '../../app/service/central/grupoDeEmpresasService'
import EmpresaService from '../../app/service/central/empresaService'
import GeneralServices from '../../app/service/generalServices'

class EmpresasMultiSelect extends React.Component {

    constructor(){
        super()
        this.grupoDeEmpresasService = new GrupoDeEmpresasService()
        this.empresaService = new EmpresaService()
        this.generalServices = new GeneralServices()
    }

    state = {

        empresasSelectList: [],
        selectedEmpresas: [],

    }

    componentDidMount(){
        this.generalServices.getEmpresasInASelectList(this.onSucess, null)
    }

    onSucess = (list) => {
        this.setState({empresasSelectList: list})
    }


    handleChange = async (event) => {
        const name = event.target.name
        const value = event.target.value
        await this.setState({ [name]: value })
        this.props.handleChange(this.state.selectedEmpresas)
    }
    
    render(){
        
        return (
            <>
            <MultiSelect
                className={this.props.inputErrorClass}
                style={{maxWidth: '300px'}}
                display="chip"
                showClear
                value={this.state.selectedEmpresas}
                options={this.state.empresasSelectList}
                name="selectedEmpresas"
                onChange={this.handleChange}
                optionLabel="label"
                // placeholder="Selecione as empresas às quais o usuário terá acesso"
                placeholder={this.props.placeholder}
                disabled={this.props.disabled}
            />
            <small id="dateErrorNotice" className="p-error">{this.props.errorMessage}</small>
            </>
        )
    }

}

export default EmpresasMultiSelect
